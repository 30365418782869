import { String } from 'typescript-string-operations';
import { serviceKeys } from './service-layer.keys';
import { Utility } from '../_helpers/utility';

export abstract class ServiceLayer {

   private static _serviceLayerKeys = {
       serviceKeys
   };

   public static get serviceLayerKeys(): { [key: string]: any } {
       return this._serviceLayerKeys;
   }

   public static getAPIRoute<K extends keyof typeof serviceKeys>(
        baseUrl: string,
        serviceKey: K,
        pathParams?: string[],
        queryString?: string): string {


       let apiRoute = (pathParams && pathParams.length > 0)
           ? `${baseUrl}${String.Format(ServiceLayer.serviceLayerKeys.serviceKeys[serviceKey], ...pathParams)}`
           : `${baseUrl}${ServiceLayer.serviceLayerKeys.serviceKeys[serviceKey]}`;


       if (Utility.isNotNullOrUndefinedOrEmpty(queryString)) {
           if (apiRoute.indexOf('?') > -1) {
               apiRoute = `${apiRoute}&${queryString}`;
           } else {
               apiRoute = `${apiRoute}?${queryString}`;
           }
       }

       return apiRoute;
   }

   public static buildAPIRoute<K extends keyof typeof serviceKeys>(baseUrl: string, serviceKey: K, pathParams?: string[]): string {

       const apiRoute = (pathParams && pathParams.length > 0)
           ? `${baseUrl}${String.Format(ServiceLayer.serviceLayerKeys.serviceKeys[serviceKey], ...pathParams)}`
           : `${baseUrl}${ServiceLayer.serviceLayerKeys.serviceKeys[serviceKey]}`;

       return apiRoute;
   }
}
