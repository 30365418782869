import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { PostsStoreService } from '../_services/in-word.store.service';
import { NotificationDialogComponent } from '../_shared/notification-dialog/notification-dialog.component';
import { EditUserDialogComponent } from '../_shared/edit-user-dialog/edit-user-dialog.component';
import { ConfirmData, ConfirmComponent } from '../_shared/confirm/confirm.component';
import * as XLSX from 'xlsx';
import { Utility } from '../_helpers/utility';


export interface UserModel {
  position: number,
  fullName: String,
  state: String,
  city: String,
  phoneNumber: String,
  isMobileVerified: boolean,
  // isEmailVerified: boolean,
  id: number,
  reference: number,
  agentId: String,
  isFreePlanUsed: boolean,
  isPlanActivied: boolean,
  isHindi: Boolean,
  isMarathi: Boolean,
  isGujarati: Boolean,
  planStartDate?: Date,
  planType: Number,
  courtType: Number,
  imei: String,
  planExpiryDate?: Date,
  isDeleted?: boolean,
}

export interface Users{
  UserModel: UserModel[];
  length: number;
}




@Component({
  selector: 'app-view-users',
  templateUrl: './view-users.component.html',
  styleUrls: ['./view-users.component.css']
})
export class ViewUsersComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('TABLE') table: ElementRef;
  PostsData: UserModel[] = [];
  buttons: MatProgressButtonOptions[] = [];
  pageSize = 20;
  pageIndex = 1;
  isLoading = false;
  public roles = Utility.getCookie('role');
  searchText = '';
  displayedColumns: string[] = ['position', 'fullName', 'phoneNumber', 'state', 'city','reference','agentId', 'planExpiryDate','isHindi','isMarathi','isGujarati','planType','courtType', 'planStartDate', 'verified', 'isFreePlanUsed', 'notification', 'delete'];
  dataSource = new MatTableDataSource<UserModel>(this.PostsData);
  constructor(private PostsStoreService: PostsStoreService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {
    this.PostsData = [];
    this.buttons = [];
    
    this.isLoading = true;
    // this.paginator.
    this.PostsStoreService.getAllUsers(this.pageSize, this.pageIndex, this.searchText).subscribe(result => {
      
      this.PostsData = result.UserModel;
      this.paginator.length = result.length;
      
      // this.paginator.pageSize = this.pageSize;
      // this.paginator.pageIndex = this.pageIndex;
      this.isLoading = false;

      this.dataSource = new MatTableDataSource<UserModel>(this.PostsData);
      // this.dataSource.paginator = this.paginator;
    });

  }
  private planTypes = [
    { value: 0, viewValue: "Civil" },
    { value: 1, viewValue: "Criminal" },
    { value: 2, viewValue: "Civil + Criminal" },
  ];

  private courtTypes = [
    { value: 0, viewValue: "Supreme Court" },
    { value: 1, viewValue: "Bombay High Court" },
    { value: 2, viewValue: "Both" }
  ];

  pageChange(event){
    this.isLoading = true;
    this.pageIndex = event.pageIndex+1;
    this.pageSize = event.pageSize;
      
    this.PostsStoreService.getAllUsers(this.pageSize, this.pageIndex, this.searchText).subscribe(result => {
      
      this.isLoading = false;
      this.PostsData = result.UserModel;
      this.paginator.length = result.length;
      // length = result.length;
      
      // this.paginator.pageSize = this.pageSize;
      // this.paginator.pageIndex = this.pageIndex;
      
      this.dataSource = new MatTableDataSource<UserModel>(this.PostsData);

      // this.dataSource.paginator = this.paginator;
    });
  }

  search(event){
    
    this.searchText = event.target.value;
    this.isLoading = true;
    this.PostsStoreService.getAllUsers(this.pageSize, this.pageIndex, this.searchText).subscribe(result => {
      
      this.PostsData = result.UserModel;
      this.paginator.length = result.length;
      this.isLoading = false;
      // length = result.length;
      
      // this.paginator.pageSize = this.pageSize;
      // this.paginator.pageIndex = this.pageIndex;
      
      this.dataSource = new MatTableDataSource<UserModel>(this.PostsData);
      // this.dataSource.paginator = this.paginator;
    });
  }
  openNotificationAllDialog() {
    const dialogRef = this.dialog.open(NotificationDialogComponent, {
      width: '550px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }
  openNotificationDialog(user) {
    const dialogRef = this.dialog.open(NotificationDialogComponent, {
      width: '550px',
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }
  // btnClick(index, id): void {
  //   
  //   this.buttons[index].active = true;
  //   this.PostsStoreService.verifyPosts(id).subscribe(result => {
  //     this.buttons[index].active = false;
  //     this.buttons[index].disabled = true;
  //     this.buttons[index].text = 'Verified';
  //   });
  // }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

  checkForPlanExpire(datetime: string) {
    return (Date.parse(datetime) < Date.now());
  }
  editPlan(user) {
    const dialogRef = this.dialog.open(EditUserDialogComponent, {
      width: '550px',
      data: user,
    });

    dialogRef.afterClosed().subscribe(result => {
      window.location.reload()
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  deleteUser(value, user) {

    
    let message = '';
    value.checked ? message = 'Are you sure you want to deactive ' + user.fullName + '?' : message = 'Are you sure you want to activate ' + user.fullName + '?';
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '550px',
      data: {
        heading: 'Confirm',
        title: message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == ConfirmData.Yes) {
        this.PostsStoreService.deactiveUser(user.id, value.checked).subscribe((syncStart) => {
          if (!syncStart) {

          }
        });
      }
    });

  }

  planChange(value, user, language) {
    let message = '';
    value.checked ? message = 'Are you sure you want to active this plan for:' + user.fullName + '?' : message = 'Are you sure you want to deactivate the plan for:' + user.fullName + '?';
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '550px',
      data: {
        heading: 'Confirm',
        title: message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == ConfirmData.Yes) {
        this.PostsStoreService.planChange(user.id, value.checked,language).subscribe((syncStart) => {
          if (!syncStart) {

          }
        });
      }
    });

  }

  planUpdate(user, planType) {
    let message = '';
    message = 'Are you sure you want to active this plan for:' + user.fullName ;
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '550px',
      data: {
        heading: 'Confirm',
        title: message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == ConfirmData.Yes) {
        this.PostsStoreService.planUpdate(user.id,planType).subscribe((syncStart) => {
          if (!syncStart) {

          }
        });
      }
    });

  }

  courtUpdate(user, courtType){
    let message = '';
    message = 'Are you sure you want to active this plan for:' + user.fullName ;
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '550px',
      data: {
        heading: 'Confirm',
        title: message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == ConfirmData.Yes) {
        this.PostsStoreService.courtUpdate(user.id,courtType).subscribe((syncStart) => {
          if (!syncStart) {

          }
        });
      }
    });
  }

  verifyUser(verify, user) {
    let message = '';
    message = 'Are you sure you want to verify this user? ';
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '550px',
      data: {
        heading: 'Confirm',
        title: message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == ConfirmData.Yes) {
        this.PostsStoreService.verifyUser(user.id,verify.checked).subscribe((syncStart) => {
          if (!syncStart) {

          }
        });
      }
    });

  }

  exportAsExcel() {
    const workSheet = XLSX.utils.json_to_sheet(this.dataSource.data);
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'SheetName');
    XLSX.writeFile(workBook, 'users.xlsx');

  }
}