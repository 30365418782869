import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { HelpComponent } from '../../_shared/help/help.component';
import { MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PostsStoreService } from 'src/app/_services/in-word.store.service';

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.css']
})
export class LoginScreenComponent implements OnInit {
  loginForm: FormGroup;
  constructor(private route: Router,
    private fb: FormBuilder,
    private postsStoreService: PostsStoreService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['',Validators.required],
      password: ['',Validators.required],
    })
  }

  login() {
    if (this.loginForm.valid) {
      let loginData = this.loginForm.getRawValue();
      this.postsStoreService.login(loginData).subscribe(response => {
        if (response) {
          this.route.navigate(['dashboard/home']);
        } else {
          alert('Wrong Username or Password..!!!');
        }
      });
    } else {
      alert('Please enter valid username or password..!!!');
    }
  }
  openHelpDialog() {
    const dialogRef = this.dialog.open(HelpComponent, {
      width: '450px',
    });
  }
}
