import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { UserModel } from '../view-users/view-users.component';
import { PostsStoreService } from '../_services/in-word.store.service';
import { PostModel } from '../posts/view-posts/view-posts.component';


export interface BookmarkModel {
  user: UserModel,
  post: PostModel
}
@Component({
  selector: 'app-view-bookmarks',
  templateUrl: './view-bookmarks.component.html',
  styleUrls: ['./view-bookmarks.component.css']
})
export class ViewBookmarksComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  bookmarksData: BookmarkModel[] = [];
  buttons: MatProgressButtonOptions[] = [];
  displayedColumns: string[] = ['fullName','phoneNumber','planExpiryDate','state','city','appelentName', 'respondentName', 'judges', 'decidedDate', 'importantPoints', 'importantPointsHindi', 'importantPointsMarathi', 'importantPointsGujrati', 'headNote', 'headNoteHindi', 'headNoteMarathi', 'headNoteGujrati', 'result', 'caseReffered', 'links'];
  dataSource = new MatTableDataSource<BookmarkModel>(this.bookmarksData);
  pageSize = 20;
  pageIndex = 1;
  isLoading = false

  constructor(private PostsStoreService: PostsStoreService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {
    this.bookmarksData = [];
    this.buttons = [];
    this.isLoading = true
    this.PostsStoreService.getAllBookmars(this.pageSize, this.pageIndex).subscribe(result => {
      
      this.bookmarksData = result;
      this.isLoading = false
      this.dataSource = new MatTableDataSource<BookmarkModel>(this.bookmarksData);
      this.dataSource.paginator = this.paginator;
    });

  }

  changePage(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    
    this.PostsStoreService.getAllBookmars(this.pageSize, this.pageIndex).subscribe(result => {
      
      this.bookmarksData = result;
      this.isLoading = false
      this.dataSource = new MatTableDataSource<BookmarkModel>(this.bookmarksData);
      this.dataSource.paginator = this.paginator;
    });
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

}
