import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { AdminModel } from 'src/app/view-admins/view-admins.component';
import { Utility } from 'src/app/_helpers/utility';
import { PostsStoreService } from 'src/app/_services/in-word.store.service';

@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.css']
})
export class AddAdminComponent implements OnInit {

  filteredRackOptions: Observable<string[]>;
  rackOptions: string[] = [];
  addAdminForm: FormGroup;
  submitted = false;

  constructor(public dialogRef: MatDialogRef<AddAdminComponent>,
    // private inwordStoreService: InWordStoreService,
    public snackBar: MatSnackBar,
    private PostsStoreService: PostsStoreService,
    @Inject(MAT_DIALOG_DATA) public data: AdminModel,
    private fb: FormBuilder) { }

    private genderList = [
      {value: 'Male', viewValue: 'Male'},
      {value: 'Female', viewValue: 'Female'}
    ];

    private roleList = [
      {value: 'superadmin', viewValue: 'Superadmin'},
      {value: 'dataentry', viewValue: 'Data Entry'},
      {value: 'agent', viewValue: 'Agent'}
    ];

    // private enabled = [
    //   {value: true , viewValue: 'True'},
    //   {value: false , viewValue: 'False'}
    // ];

  ngOnInit() {
    this.addAdminForm = this.fb.group({
      email: new FormControl("", [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      language: new FormControl("Required", Validators.required),
      type: new FormControl("", Validators.required),
      months: new FormControl("", Validators.required),
    });
    if (Utility.isNotNullOrUndefinedOrEmpty(this.data)) {
      this.addAdminForm = this.fb.group({
        id: [this.data.id],
        fullName: [this.data.fullName],
        email: [this.data.email],
        mobile: [this.data.mobile],
        agentId: [this.data.agentId],
        discountValue: [this.data.discountValue],
        gender: [this.data.gender],
        password: [this.data.password],
        role: [this.data.role],
        enabled: [this.data.enabled]
      });
    } else {
      this.addAdminForm = this.fb.group({
        id:[],
        fullName: [],
        email: [],
        agentId: [],
        discountValue: [],
        mobile: [],
        gender: [],
        password: [],
        role: [],
        enabled: [true]
      });
    }

  }

  saveRecord() {
    if (this.addAdminForm.valid) {
    if (Utility.isNotNullOrUndefinedOrEmpty(this.data)) {
      const adminsData = this.addAdminForm.getRawValue();
      adminsData.enabled ? adminsData.enabled = true : adminsData.enabled = false;
      this.PostsStoreService.updateAdminModel(adminsData).subscribe(response => {
        this.openSnackBar('Record Updated Successfully', 'Ok');
        this.dialogRef.close();
      });
    } else{
    const adminsData = this.addAdminForm.getRawValue();
    this.PostsStoreService.addAdmin(adminsData).subscribe(response => {
      this.openSnackBar('Record Added Successfully', 'Ok');
      this.dialogRef.close();
    });
  }
    } else{
      this.submitted = true;
      Utility.markFormGroupTouched(this.addAdminForm);
    }
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  get discountValue() {
    return this.addAdminForm.get('discountValue');
  }

  get password() {
    return this.addAdminForm.get('password');
  }

  get agentId() {
    return this.addAdminForm.get('agentId');
  }

  get fullName() {
    return this.addAdminForm.get('fullName');
  }

  get mobile() {
    return this.addAdminForm.get('mobile');
  }

  get role() {
    return this.addAdminForm.get('role');
  }

  get email() {
    return this.addAdminForm.get('email');
  }

  get gender() {
    return this.addAdminForm.get('gender');
  }
}
