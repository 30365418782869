import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { routerNgProbeToken } from "@angular/router/src/router_module";
import { Utility } from "src/app/_helpers/utility";
import { PostsStoreService } from "src/app/_services/in-word.store.service";

declare const CKEDITOR;
@Component({
  selector: "app-add-posts",
  templateUrl: "./add-posts.component.html",
  styleUrls: ["./add-posts.component.css"],
})
export class AddPostsComponent implements OnInit {
  @ViewChild('editor') editor: ElementRef;
  
  editForm: FormGroup;
  public data;
  loading = false;
  public id;
  public role = Utility.getCookie('role');
  submitted = false;
  public isEdit =false;
  // contentsCss: 'p{margin-top:0px;margin-bottom:0px;}ol{margin:0px;}',
  public config = {height: '500px',tabSpaces:4,docType :'<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN">',basicEntities :true,fullPage:false};
  constructor(
    private router: Router,
    private fb: FormBuilder,
    public route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private PostsStoreService: PostsStoreService,
    public datepipe: DatePipe
  ) {
    this.id = this.route.snapshot.paramMap["params"]["id"];
    if (this.id) {
      this.isEdit = true;
      this.PostsStoreService.getPostById(this.id).subscribe((res) => {
        this.data = res;
        this.buildForm(true);
      });
    } else{
      this.buildForm(false);
    }
  }
  ngOnInit(): void {
    // CKEDITOR.on('instanceReady', function(ev) {
    //   ev.editor.on('paste', function(evt) { 
    //     evt.data.dataValue = evt.data.dataValue.replace(/&nbsp;/g,'');
    //     // evt.data.dataValue = evt.data.dataValue.replace(/<p><\/p>/g,'');
    //     console.log(evt.data.dataValue);
    //   }, null, null, 9);
    // });
  }

  onUpdateClick() {
    this.loading = true;
    let updatedData = this.editForm.getRawValue();
    this.PostsStoreService.updatePost(updatedData).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.openSnackBar("Record Updated Successfully", "Ok");
        this.router.navigate(['dashboard/Posts']);
      } else {
        this.openSnackBar("Error While Updating Record", "Ok");
      }
    });
  }

  courtSubTyp(data:any){
    const type:any=data.value
      if(type == 0){
      this.editForm.controls['courtSubType'].setValue(-1)
      }
      // this.editForm.patchValue({courtSubTyp:-1});
  }

  onSaveClick() {
    if (this.editForm.valid) {
      this.loading = true;
    let updatedData = this.editForm.getRawValue();
    this.PostsStoreService.addPost(updatedData).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.openSnackBar("Record Addedd Successfully", "Ok");
        this.router.navigate(['dashboard/Posts']);
      } else {
        this.openSnackBar("Error While Adding Record", "Ok");        
      }
    });
  } else{
    this.submitted = true;
    Utility.markFormGroupTouched(this.editForm);
  }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  private typeList = [
    { value: "Judgement", viewValue: "Judgement" },
    { value: "Order", viewValue: "Order" }
  ];

  private inFavourOfList = [
    { value: 0, viewValue: "Appelent" },
    { value: 1, viewValue: "Respondent" },
    { value: 2, viewValue: "None" }
  ];

  private courtTypeList = [
    { value: 0, viewValue: "Supreme Court" },
    { value: 1, viewValue: "Bombay High Court" }
  ];

  private courtSubTypeList = [
    { value: 0, viewValue: "None" },
    { value: 1, viewValue: "Nagpur Bench" },
    { value: 2, viewValue: "Aurangabad Bench" },
    { value: 3, viewValue: "Goa Bench" }
  ];

  private postTypeList = [
    { value: 0, viewValue: "Civil" },
    { value: 1, viewValue: "Criminal" }
  ];

  buildForm(edit) {
    if (this.id && edit) {
      this.editForm = this.fb.group({
        id: [this.data.id],
        appelentName: [this.data.appelentName],
        respondentName: [this.data.respondentName],
        judges: [this.data.judges],
        decidedDate: [
          this.datepipe.transform(
            this.data.decidedDate,
            "yyyy-MM-ddThh:mm"
          ),
        ],
        postType: [this.data.postType],
        courtType: [this.data.courtType],
        courtSubType: [this.data.courtSubType],
        inFavourOf: [this.data.inFavourOf],
        importantPoints: [this.data.importantPoints],
        importantPointsHindi: [this.data.importantPointsHindi],
        importantPointsMarathi: [this.data.importantPointsMarathi],
        importantPointsGujrati: [this.data.importantPointsGujrati],
        headNote: [this.data.headNote],
        headNoteHindi: [this.data.headNoteHindi],
        headNoteMarathi: [this.data.headNoteMarathi],
        headNoteGujrati: [this.data.headNoteGujrati],
        result: [this.data.result],
        resultHindi: [this.data.resultHindi],
        priority: [this.data.priority],
        resultMarathi: [this.data.resultMarathi],
        resultGujrati: [this.data.resultGujrati],
        caseReffered: [this.data.caseReffered],
        actsReffered: [this.data.actsReffered],
        type: [this.data.type],
        fullJudgement: [this.data.fullJudgement],
        links: [this.data.links],
        pid: [this.data.pid],
      });
    } else {
      this.editForm = this.fb.group({
        id: [],
        appelentName: [],
        respondentName: [],
        judges: [],
        postType: [],
        courtType: [],
        inFavourOf: [],
        courtSubType: [],
        decidedDate: [],
        importantPoints: [],
        importantPointsHindi: [],
        importantPointsMarathi: [],
        importantPointsGujrati: [],
        type: [],
        fullJudgement: [],
        headNote: [],
        headNoteHindi: [],
        headNoteMarathi: [],
        headNoteGujrati: [],
        result: [],
        priority: [],
        resultHindi: [],
        resultMarathi: [],
        resultGujrati: [],
        caseReffered: [],
        actsReffered: [],
        links: [],
        pid: ['1'],
      });
    }
  }
  
get appelentName(){
  return this.editForm.get('appelentName');
}
get respondentName(){
  return this.editForm.get('respondentName');
}
get judges(){
  return this.editForm.get('judges');
}
get decidedDate(){
  return this.editForm.get('decidedDate');
}
get fullJudgement(){
  return this.editForm.get('fullJudgement');
}
get headNote(){
  return this.editForm.get('headNote');
}
get postType(){
  return this.editForm.get('postType');
}
get courtType(){
  return this.editForm.get('courtType');
}
get courtSubType(){
  return this.editForm.get('courtSubType');
}
get inFavourOf(){
  return this.editForm.get('inFavourOf');
}
get type(){
  return this.editForm.get('type');
}
get result(){
  return this.editForm.get('result');
}
get links(){
  return this.editForm.get('links');
}



}
