import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { UserModel } from 'src/app/view-users/view-users.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PostsStoreService } from 'src/app/_services/in-word.store.service';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.css']
})
export class NotificationDialogComponent implements OnInit {
  messageForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    public postsStoreService: PostsStoreService,
    // private inwordStoreService: InWordStoreService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: UserModel,
    private fb: FormBuilder) { }

  ngOnInit() {
    if (this.data) {
      this.messageForm = this.fb.group({
        mornotification: ['', Validators.required],
        title: ['', Validators.required],
        message: ['', Validators.required],
        user: [this.data]
      });
    } else {
      this.messageForm = this.fb.group({
        mornotification: ['', Validators.required],
        type: ['', Validators.required],
        title: ['', Validators.required],
        message: ['', Validators.required],
        user: []
      });
    }
  }

  send() {
    if (this.messageForm.valid) {
      if (this.messageForm.controls['mornotification'].value == 'Mail') {
        if (this.data) {
          let messageData = this.messageForm.getRawValue();
          this.postsStoreService.mail(messageData).subscribe(response => {
            if (response) {
              this.openSnackBar('Mail Send Successfully', 'Ok');
              this.dialogRef.close();
              location.reload();
            } else {
              this.openSnackBar('Error While Sending Mail', 'Ok');
              this.dialogRef.close();
            }

          });
        }
        else{
          let messageData = this.messageForm.getRawValue();

          this.postsStoreService.mailToAll(messageData).subscribe(response => {
            if (response) {
              this.openSnackBar('Mail Send Successfully', 'Ok');
              this.dialogRef.close();
              location.reload();
            } else {
              this.openSnackBar('Error While Sending Mail', 'Ok');
              this.dialogRef.close();
            }
          });
        }
      } else {
        if (this.data) {
          let messageData = this.messageForm.getRawValue();
          this.postsStoreService.notification(messageData).subscribe(response => {
            if (response) {
              this.openSnackBar('Notification Send Successfully', 'Ok');
              this.dialogRef.close();
              location.reload();
            } else {
              this.openSnackBar('Error While Sending Notification', 'Ok');
              this.dialogRef.close();
            }

          });
        } else {
          let messageData = this.messageForm.getRawValue();

          this.postsStoreService.notificationToAll(messageData).subscribe(response => {
            if (response) {
              this.openSnackBar('Notification Send Successfully', 'Ok');
              this.dialogRef.close();
              location.reload();
            } else {
              this.openSnackBar('Error While Sending Notification', 'Ok');
              this.dialogRef.close();
            }
          });
        }
      }
    } else {
      this.openSnackBar('Please fill all the details', 'Ok');
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
