import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utility } from '../_helpers/utility';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  screenWidth: number;
  public role = Utility.getCookie('role');
  constructor(public router:Router) {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
    };
  }

  ngOnInit() {
  }

  logout(){
    Utility.storeCookie('token','');
    Utility.storeCookie('role','');
    this.router.navigate(['']);
  }

}
