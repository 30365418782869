import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { PostsStoreService } from 'src/app/_services/in-word.store.service';
import { UserModel } from 'src/app/view-users/view-users.component';

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.css']
})
export class EditUserDialogComponent implements OnInit {

  editUserForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<EditUserDialogComponent>,
    public postsStoreService: PostsStoreService,
    // private inwordStoreService: InWordStoreService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: UserModel,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.editUserForm = this.fb.group({
      planExpiryDate: [this.data.planExpiryDate, Validators.required],
      user: this.data,
    });
  }

  update() {
    if (this.editUserForm.valid) {
      if (this.data) {
        let userDateData = this.editUserForm.getRawValue();
        new Date(Date.parse(userDateData.planExpiryDate)).toISOString();
        this.postsStoreService.updatePlan(userDateData).subscribe(response => {
          if (response) {
            this.openSnackBar('User Updated Successfully', 'Ok');
            this.dialogRef.close();
            // location.reload();
          } else {
            this.openSnackBar('Error While User Update', 'Ok');
            this.dialogRef.close();
          }
        });
      }
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
