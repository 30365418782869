import { FormArray, FormControl, FormGroup } from "@angular/forms";

export class Utility {

    public static isNullOrUndefinedOrEmpty(value: any): boolean {
        if (value === null || value === undefined) {
            return true;
        } else if (typeof value === 'string') {
            return value.trim() === '';
        }
    }
    public static isNotNullOrUndefinedOrEmpty(value: any): boolean {
        return !Utility.isNullOrUndefinedOrEmpty(value);
    }
    public static storeCookie(key,value){
        localStorage.setItem(key,value);
    }

    public static getCookie(key){
        return localStorage.getItem(key);
    }

    public static markFormGroupTouched(formGroup: any) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
    
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {               
                this.markFormGroupTouched(control);
            } else if (control instanceof FormArray) {  
                this.markFormGroupTouched(control);
            }
        });
      }
}