import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatTableDataSource } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { UserModel } from '../view-users/view-users.component';
import { PostsStoreService } from '../_services/in-word.store.service';
import * as XLSX from 'xlsx';

export interface ReferenceModel {
  name: String,
  mobile: Number,
  user: UserModel
}

@Component({
  selector: 'app-view-references',
  templateUrl: './view-references.component.html',
  styleUrls: ['./view-references.component.css']
})
export class ViewReferencesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  referenceData: ReferenceModel[] = [];
  buttons: MatProgressButtonOptions[] = [];
  displayedColumns: string[] = ['name','mobile','fullName','phoneNumber','planExpiryDate','state','city','status','comments'];
  dataSource = new MatTableDataSource<ReferenceModel>(this.referenceData);
  pageSize = 100;
  pageIndex = 1;
  isLoading = false

  private status = [
    { value: true, viewValue: "Contacted" },
    { value: false, viewValue: "Not Contacted" }
  ];

  constructor(private PostsStoreService: PostsStoreService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) { 
      this.referenceData = [];
    this.buttons = [];
    this.isLoading = true
    this.PostsStoreService.getAllReferences(this.pageSize, this.pageIndex).subscribe(result => {
      this.referenceData = result;
      this.isLoading = false
      this.dataSource = new MatTableDataSource<ReferenceModel>(this.referenceData);
      this.dataSource.paginator = this.paginator;
    });
    }

    changePage(event){
      this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;

    this.isLoading = true
    this.PostsStoreService.getAllReferences(this.pageSize, this.pageIndex).subscribe(result => {
      this.referenceData = result;
      this.isLoading = false
      this.dataSource = new MatTableDataSource<ReferenceModel>(this.referenceData);
      this.dataSource.paginator = this.paginator;
    });
    }

    exportAsExcel() {
      const workSheet = XLSX.utils.json_to_sheet(this.dataSource.data);
      const workBook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'SheetName');
      XLSX.writeFile(workBook, 'references.xlsx');
  
    }

  commentChange(status,reference){
    this.PostsStoreService.commentChange(reference.id, status).subscribe((syncStart) => {
      if (!syncStart) {

      }
    });
  }

  comment(reference,comment){
    this.PostsStoreService.comment(reference.id, comment).subscribe((syncStart) => {
      if (!syncStart) {

      }
    });
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

}
