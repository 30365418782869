import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatTableDataSource } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { Utility } from '../_helpers/utility';
import { PostsStoreService } from '../_services/in-word.store.service';
import { AddComponent } from '../_shared/add-plan/add.component';
import { ConfirmComponent, ConfirmData } from '../_shared/confirm/confirm.component';
import * as moment from 'moment'


export interface PlanModel {
  id: String,
  no: String,
  courtType: Number;
  language: Number,
  type: Number,
  months: Number,
  discount: Number,
  total: Number,
  cost: Number,
  order: Number,
  planExpiry: Date,
  enabled: boolean
}

export interface PlanModelList {
  count: number;
  data: PlanModel[]
}

@Component({
  selector: 'app-view-plans',
  templateUrl: './view-plans.component.html',
  styleUrls: ['./view-plans.component.css']
})
export class ViewPlansComponent implements OnInit {
  today = moment().startOf("day").toISOString()

  @ViewChild(MatPaginator) paginator: MatPaginator;
  plansData: PlanModel[] = [];
  public roles = Utility.getCookie('role');
  buttons: MatProgressButtonOptions[] = [];
  isLoading = false
  displayedColumns: string[] = ['no', 'language', 'courtType','type', 'months', 'discount', 'total', 'cost', 'order', 'planExpiry', 'edit'];
  dataSource = new MatTableDataSource<PlanModel>(this.plansData);

  constructor(private PostsStoreService: PostsStoreService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {
    this.plansData = [];
    this.buttons = [];
    this.isLoading = true
    
    this.PostsStoreService.getAllPlans().subscribe(result => {
      
      this.plansData = result;
      this.isLoading = false
      this.dataSource = new MatTableDataSource<PlanModel>(this.plansData);
      this.dataSource.paginator = this.paginator;
    });

  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

  addPlan(element): void {
    const dialogRef = this.dialog.open(AddComponent, {
      width: '550px',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = true
      this.PostsStoreService.getAllPlans().subscribe(result => {
        
        this.plansData = result;
        this.isLoading = false
        this.dataSource = new MatTableDataSource<PlanModel>(this.plansData);
        this.dataSource.paginator = this.paginator;
      });
    });
  }

  updatePlan(value, plan) {
    
    let message = '';
    value.checked ? message = 'Are you sure you want to activate plan ' + plan.name + '?' : message = 'Are you sure you want to deactive plan ' + plan.name + '?';
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '550px',
      data: {
        heading: 'Confirm',
        title: message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == ConfirmData.Yes) {
        plan.enabled = value.checked;
        this.PostsStoreService.updatePlanModel(plan).subscribe(response => {
          this.openSnackBar('Record Updated Successfully', 'Ok');
        });
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
