import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxSortableModule } from 'ngx-sortable'
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginScreenComponent } from './login/login-screen/login-screen.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './_shared/header/header.component';
import { FooterComponent } from './_shared/footer/footer.component';
import { SearchComponent } from './search/search.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './_services/error.handle.service';
import { MessageService } from './_services/message.service';
import { DataService } from './_services/_base.services';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { DeleteComponent } from './_shared/delete/delete.component';
import { HelpComponent } from './_shared/help/help.component';
import { CommonModule, DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ExcelService } from './_services/excel-service';
import { EditComponent } from './_shared/edit/edit.component';
import { MiniDashboardComponent } from './dashboard/mini-dashboard/mini-dashboard.component';
import { ViewPostsComponent } from './posts/view-posts/view-posts.component';
import { PostsStoreService } from './_services/in-word.store.service';
import { Postservice } from './_services/in-word.services';
import { ViewUsersComponent } from './view-users/view-users.component';
import { AddEditDialogComponent } from './posts/view-posts/add-edit-dialog/add-edit-dialog.component';
import { NotificationDialogComponent } from './_shared/notification-dialog/notification-dialog.component';
import { ViewBookmarksComponent } from './view-bookmarks/view-bookmarks.component';
import { EditUserDialogComponent } from './_shared/edit-user-dialog/edit-user-dialog.component';
import { ViewOfflinePostsComponent } from './posts/view-offline-posts/view-offline-posts.component';
import { ConfirmComponent } from './_shared/confirm/confirm.component';
import { WarningComponent } from './_shared/warning/warning.component';
import { ViewMailsComponent } from './view-mails/view-mails.component';
import { ViewPlansComponent } from './view-plans/view-plans.component';
import { ViewPaymentsComponent } from './view-payments/view-payments.component';
import { AddComponent } from './_shared/add-plan/add.component';
import { AddPostsComponent } from './posts/add-posts/add-posts.component';
import { AddAdminComponent } from './_shared/add-admin/add-admin.component';
import { ViewAdminsComponent } from './view-admins/view-admins.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule } from '@angular/forms';
import { ViewReferencesComponent } from './view-references/view-references.component';
import { SuggestionComponent } from './_shared/suggestion/suggestion.component';
import { ViewSuggestionComponent } from './view-suggestion/view-suggestion.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginScreenComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    SearchComponent,
    DeleteComponent,
    HelpComponent,
    EditComponent,
    MiniDashboardComponent,
    ViewPostsComponent,
    ViewUsersComponent,
    AddEditDialogComponent,
    NotificationDialogComponent,
    ViewBookmarksComponent,
    ViewMailsComponent,
    EditUserDialogComponent,
    ViewOfflinePostsComponent,
    ViewPlansComponent,
    ViewPaymentsComponent,
    ConfirmComponent,
    WarningComponent,
    AddComponent,
    AddPostsComponent,
    AddAdminComponent,
    ViewAdminsComponent,
    ViewReferencesComponent,
    SuggestionComponent,
    ViewSuggestionComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CKEditorModule,
    NgxSortableModule,
    FormsModule, 
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatProgressButtonsModule,
    CommonModule,
  ],
  entryComponents: [
    DeleteComponent,
    HelpComponent,
    EditComponent,
    AddEditDialogComponent,
    NotificationDialogComponent,
    SuggestionComponent,
    EditUserDialogComponent,
    ConfirmComponent,
    WarningComponent,
    AddComponent
  ],
  providers: [
    HttpErrorHandler,
    MessageService,
    DataService,
    Postservice,
    PostsStoreService,
    ExcelService,
    DatePipe,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
