import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { UserModel } from '../view-users/view-users.component';
import { PostsStoreService } from '../_services/in-word.store.service';
import { PostModel } from '../posts/view-posts/view-posts.component';


export interface MailModel {
  no: String,
  to: String,
  from: String
  subject: String
  message: String,
  mailId: String
}
@Component({
  selector: 'app-view-mails',
  templateUrl: './view-mails.component.html',
  styleUrls: ['./view-mails.component.css']
})
export class ViewMailsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  mailsData: MailModel[] = [];
  buttons: MatProgressButtonOptions[] = [];
  displayedColumns: string[] = ['no', 'to', 'from', 'subject', 'message', 'mailId'];
  dataSource = new MatTableDataSource<MailModel>(this.mailsData);

  constructor(private PostsStoreService: PostsStoreService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {
    this.mailsData = [];
    this.buttons = [];
    
    this.PostsStoreService.getAllMails().subscribe(result => {
      
      this.mailsData = result;
      this.dataSource = new MatTableDataSource<MailModel>(this.mailsData);
      this.dataSource.paginator = this.paginator;
    });

  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

}
