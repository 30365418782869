import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { UserModel } from '../view-users/view-users.component';
import { PostsStoreService } from '../_services/in-word.store.service';
import { PostModel } from '../posts/view-posts/view-posts.component';


export interface PaymentModel {
  no: String,
  txtId: String,
  txtAmount: Number,
  planId: String,
  discountValue: number,
  userId: String,
  dateCreated: Date,
  agentId: String,
  user: UserModel,
}
@Component({
  selector: 'app-view-payments',
  templateUrl: './view-payments.component.html',
  styleUrls: ['./view-payments.component.css']
})
export class ViewPaymentsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  paymentsData: PaymentModel[] = [];
  isLoading = false
  buttons: MatProgressButtonOptions[] = [];
  displayedColumns: string[] = ['no', 'txtId', 'txtAmount','discount','total', 'fullName', 'phoneNumber'];
  dataSource = new MatTableDataSource<PaymentModel>(this.paymentsData);
  pageSize = 100;
  pageIndex = 1;
  status = null

  constructor(private PostsStoreService: PostsStoreService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {
    this.paymentsData = [];
    this.buttons = [];
    this.isLoading = true
    this.PostsStoreService.getAllPayments(this.status).subscribe(result => {
      
      this.paymentsData = result;
      this.isLoading = false
      this.dataSource = new MatTableDataSource<PaymentModel>(this.paymentsData);
      this.dataSource.paginator = this.paginator;
    });

  }

  private userType = [
    { value: 0, viewValue: "Free" },
    { value: 1, viewValue: "Paid" }
  ];

  userCheck(event){
    this.isLoading = true
    this.PostsStoreService.getAllPayments(event).subscribe(result => {
      
      this.paymentsData = result;
      this.isLoading = false
      this.dataSource = new MatTableDataSource<PaymentModel>(this.paymentsData);
      this.dataSource.paginator = this.paginator;
    });
  }

  changePage(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    
    this.PostsStoreService.getAllPayments(this.status).subscribe(result => {
      
      this.paymentsData = result;
      this.isLoading = false
      this.dataSource = new MatTableDataSource<PaymentModel>(this.paymentsData);
      this.dataSource.paginator = this.paginator;
    });
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

}
