import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginScreenComponent } from "./login/login-screen/login-screen.component";
import { SearchComponent } from "./search/search.component";
import { MiniDashboardComponent } from "./dashboard/mini-dashboard/mini-dashboard.component";
import { ViewPostsComponent } from "./posts/view-posts/view-posts.component";
import { ViewUsersComponent } from "./view-users/view-users.component";
import { ViewBookmarksComponent } from "./view-bookmarks/view-bookmarks.component";
import { ViewOfflinePostsComponent } from "./posts/view-offline-posts/view-offline-posts.component";
import { ViewMailsComponent } from "./view-mails/view-mails.component";
import { ViewPlansComponent } from "./view-plans/view-plans.component";
import { ViewPaymentsComponent } from "./view-payments/view-payments.component";
import { AddPostsComponent } from "./posts/add-posts/add-posts.component";
import { AddAdminComponent } from "./_shared/add-admin/add-admin.component";
import { ViewAdminsComponent } from "./view-admins/view-admins.component";
import { ViewReferencesComponent } from "./view-references/view-references.component";
import { ViewSuggestionComponent } from "./view-suggestion/view-suggestion.component";
const routes: Routes = [
  { path: "", component: LoginScreenComponent },
  { path: "login", component: LoginScreenComponent },
  { path: "search", component: SearchComponent },
  {
    path: "dashboard",
    component: DashboardComponent,
    children: [
      { path: "home", component: MiniDashboardComponent },
      { path: "addAdmins", component: AddAdminComponent },
      { path: "Admins", component: ViewAdminsComponent },
      { path: "Users", component: ViewUsersComponent },
      { path: "Posts", component: ViewPostsComponent },
      { path: "addPost", component: AddPostsComponent },
      { path: "addPost/:id", component: AddPostsComponent },
      { path: "Offline", component: ViewOfflinePostsComponent },
      { path: "Bookmarks", component: ViewBookmarksComponent },
      { path: "Mails", component: ViewMailsComponent },
      { path: "Plans", component: ViewPlansComponent },
      { path: "Payments", component: ViewPaymentsComponent },
      { path: "References", component: ViewReferencesComponent },
      { path: "Suggestion", component: ViewSuggestionComponent }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
