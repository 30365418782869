export class InWordEntry {
    pid: string;
    sid: string;
    place: string;
    quanity: number;
    date: string;
    no?: number;
    rid?: string;
    remark?: string;
    avaiableStock?: number;
}
