import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { PostsStoreService } from 'src/app/_services/in-word.store.service';
import { MatProgressButtonOptions } from 'mat-progress-buttons';

import { DeleteComponent } from 'src/app/_shared/delete/delete.component';
import { PostModel } from '../view-posts/view-posts.component';
import { SelectionModel } from '@angular/cdk/collections';
import { WarningComponent } from 'src/app/_shared/warning/warning.component';


@Component({
  selector: 'app-view-offline-posts',
  templateUrl: './view-offline-posts.component.html',
  styleUrls: ['./view-offline-posts.component.css']
})
export class ViewOfflinePostsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  PostsData: PostModel[] = [];
  buttons: MatProgressButtonOptions[] = [];
  displayedColumns: string[] = ['edit', 'select', 'No', 'pid', 'borrowerName', 'bankName', 'propertyType', 'location', 'city', 'priceReserve', 'emdAmount', 'bidIncrementValue', 'lastDateOfETenderSubmission', 'auctionStartDateTime', 'auctionEndDateTime', 'eAuctionWebsite','attachments'];
  dataSource = new MatTableDataSource<PostModel>(this.PostsData);
  pageSize = 10;
  pageIndex = 1;
  latestPID;
  initialSelection = [];
  selectedPIDs = [];
  selectedIds = [];
  allowMultiSelect = true;
  selection = new SelectionModel<PostModel>(this.allowMultiSelect, this.initialSelection);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PostModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.pid}`;
  }

  constructor(private PostsStoreService: PostsStoreService, public dialog: MatDialog, public snackBar: MatSnackBar) {
    this.PostsData = [];
    this.buttons = [];
    this.getData();
  }

  getData() {
    this.PostsStoreService.getAllOfflinePosts(this.pageSize, this.pageIndex).subscribe(result => {
      if (this.pageIndex == 1) {
        this.latestPID = result.data[0].pid;
      }
      this.PostsData = result.data;
      this.dataSource = new MatTableDataSource<PostModel>(this.PostsData);
      this.paginator.length = result.count;
    });
  }
  openDeleteDialog(element: PostModel): void {
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: '550px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.PostsStoreService.deletePost(element.id).subscribe(result => {
          if (result) {
            this.openSnackBar('Successfully Deleted Record', 'Ok');
            location.reload();
          } else {
            this.openSnackBar('Error While Deleting Record', 'Ok');
          }
        });
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  openDialog(element: PostModel): void {
    // const dialogRef = this.dialog.open(AddEditDialogComponent, {
    //   width: '550px',
    //   data: {
    //     post: element,
    //     isEdit: true,
    //   },
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    //   // this.animal = result;
    // });
  }

  openDialogAdd(): void {
    // const dialogRef = this.dialog.open(AddEditDialogComponent, {
    //   width: '550px',
    //   data: {
    //     post: {
    //       pid: this.latestPID
    //     },
    //     isEdit: false,
    //   },
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    //   // this.animal = result;
    // });
  }

  changePage(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    
    this.getData();
  }


  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.selection.onChange.subscribe((result) => {
      this.selectedIds = [];
      this.selectedPIDs = [];
      this.selection.selected.forEach((select) => {
        this.selectedPIDs.push(select.pid);
        this.selectedIds.push(select.id);
      })
    })
  }
  
  // goLive(){
  //   if(this.selectedIds.length > 0){
  //     this.PostsStoreService.goLive(this.selectedIds).subscribe((result)=>{
  //       if(result){
  //         this.dialog.open(WarningComponent, {
  //           data: {
  //             heading: 'Congrate!!!',
  //             title: 'The posts are live now',
  //             buttonText: 'Okay'
  //           }
  //         });
  //         this.getData();
  //       } else{
  //         this.dialog.open(WarningComponent, {
  //           data: {
  //             heading: 'Error!!!',
  //             title: 'Error while updating posts',
  //             buttonText: 'Okay'
  //           }
  //         });
  //       }
  //     })
  //   } else{
  //     this.dialog.open(WarningComponent, {
  //       data: {
  //         heading: '0 Post Selected',
  //         title: 'Please select the posts!!!',
  //         buttonText: 'Okay'
  //       }
  //     });
  //   }
  // }

}
