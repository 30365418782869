import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatTableDataSource } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { Utility } from '../_helpers/utility';
import { PostsStoreService } from '../_services/in-word.store.service';
import { ConfirmComponent, ConfirmData } from '../_shared/confirm/confirm.component';
import { SuggestionComponent } from '../_shared/suggestion/suggestion.component';


export interface SuggestionModel {
  id: String,
  suggestion: string
}

export interface SuggestionModelList {
  count: number;
  data: SuggestionModel[]
}

@Component({
  selector: 'app-view-suggestion',
  templateUrl: './view-suggestion.component.html',
  styleUrls: ['./view-suggestion.component.css']
})
export class ViewSuggestionComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  suggestionData: SuggestionModel[] = [];
  public roles = Utility.getCookie('role');
  isLoading = false
  buttons: MatProgressButtonOptions[] = [];
  displayedColumns: string[] = ['suggestion','delete'];
  dataSource = new MatTableDataSource<SuggestionModel>(this.suggestionData);

  constructor(private PostsStoreService: PostsStoreService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {
      this.suggestionData = [];
    this.buttons = [];
    this.isLoading = true
    this.PostsStoreService.getAllSuggestion().subscribe(result => {
      
      this.suggestionData = result;
      this.isLoading = false
      this.dataSource = new MatTableDataSource<SuggestionModel>(this.suggestionData);
      this.dataSource.paginator = this.paginator;
    });
     }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

  openDeleteDialog(element: SuggestionModel): void {
    let message = '';
    message = 'Are you sure you want to delete this suggestion?';
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '550px',
      data: {
        heading: 'Confirm',
        title: message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == ConfirmData.Yes) {
        this.PostsStoreService.deleteSuggestion(element.id).subscribe(result => {
          if (result) {
            this.openSnackBar('Successfully Deleted Record', 'Ok');
          } else {
            this.openSnackBar('Error While Deleting Record', 'Ok');
          }
        });
      }
    });
  }

  addSuggestion(element): void {
    const dialogRef = this.dialog.open(SuggestionComponent, {
      width: '550px',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = true
      this.PostsStoreService.getAllSuggestion().subscribe(result => {
        
        this.suggestionData = result;
        this.isLoading = false
        this.dataSource = new MatTableDataSource<SuggestionModel>(this.suggestionData);
        this.dataSource.paginator = this.paginator;
      });
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
