import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { PostsStoreService } from 'src/app/_services/in-word.store.service';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { AddEditDialogComponent } from './add-edit-dialog/add-edit-dialog.component';
import { DeleteComponent } from 'src/app/_shared/delete/delete.component';
import { Router } from '@angular/router';
import { Utility } from 'src/app/_helpers/utility';
import { ConfirmComponent, ConfirmData } from 'src/app/_shared/confirm/confirm.component';
import * as moment from 'moment'



export interface PostModel {
  id: String;
  no: String;
  postType: Number;
  courtType: Number;
  inFavourOf: Number;
  courtSubType: Number;
  appelentName: String;
  respondentName: String;
  fullJudgement: String;
  type: String;
  judges: String;
  decidedDate: String;
  importantPoints: String;
  importantPointsHindi: String;
  importantPointsMarathi: String;
  importantPointsGujrati: String;
  headNote: String;
  headNoteHindi: String;
  headNoteMarathi: String;
  headNoteGujrati: String;
  result: String;
  priority: Number
  resultHindi: String;
  resultMarathi: String;
  resultGujrati: String;
  caseReffered: String;
  actsReffered: String;
  links: String;
  enabled: Boolean;
  isBookmarked: Boolean;
  pid: String;
}

export interface PostModeList {
  count: number;
  data: PostModel[]
  length: number;
}



@Component({
  selector: 'app-view-Posts',
  templateUrl: './view-posts.component.html',
  styleUrls: ['./view-posts.component.css']
})
export class ViewPostsComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  PostsData: PostModel[] = [];
  buttons: MatProgressButtonOptions[] = [];
  displayedColumns: string[] = ['edit', 'No', 'pid','priority','inFavourOf','courtType','courtSubType','postType', 'appelentName', 'respondentName', 'judges', 'decidedDate', 'importantPoints', 'importantPointsHindi', 'importantPointsMarathi', 'importantPointsGujrati', 'headNote', 'headNoteHindi', 'headNoteMarathi', 'headNoteGujrati', 'result','resultHindi','resultMarathi','resultGujrati', 'caseReffered', 'links'];
  dataSource = new MatTableDataSource<PostModel>(this.PostsData);
  pageSize = 20;
  pageIndex = 1;
  public roles = Utility.getCookie('role');
  date = '';
  searchText = ''
  isLoading = false;
  latestPID;

  constructor(private PostsStoreService: PostsStoreService, public router:Router,public dialog: MatDialog, public snackBar: MatSnackBar) {
    this.PostsData = [];
    this.buttons = [];
    this.getData();
  }

  searchDate(event){
    
    this.date = event.target.valueAsDate.toISOString()
    this.date = this.date.split('T')[0]
    this.isLoading = true;
    this.PostsStoreService.getAllPosts(this.pageSize, this.pageIndex,this.date,'').subscribe(result => {
      this.PostsData = result.data;
      this.paginator.length = result.length;
      this.isLoading = false;
      // length = result.length;
      
      // this.paginator.pageSize = this.pageSize;
      // this.paginator.pageIndex = this.pageIndex;
      this.dataSource = new MatTableDataSource<PostModel>(this.PostsData);
      this.paginator.length = result.count;
      // this.dataSource.paginator = this.paginator;
    });
  }

  searchData(event){
    
    this.searchText = event.target.value
    try {
    this.isLoading = true;
    this.PostsStoreService.getAllPosts(this.pageSize, this.pageIndex,'',this.searchText).subscribe(result => {
      this.PostsData = result.data;
      this.paginator.length = result.length;
      this.isLoading = false;
      // length = result.length;
      
      // this.paginator.pageSize = this.pageSize;
      // this.paginator.pageIndex = this.pageIndex;
      this.dataSource = new MatTableDataSource<PostModel>(this.PostsData);
      this.paginator.length = result.count; 
      // this.dataSource.paginator = this.paginator;
    });
  } catch(error){
    this.isLoading = false
  }
  }

  getData() {
    this.isLoading = true
    this.PostsStoreService.getAllPosts(this.pageSize, this.pageIndex,'','').subscribe(result => {
      if (this.pageIndex == 1) {
        this.latestPID = result.data[0].pid;
      }
      this.PostsData = result.data;
      // var splitt = result.data[0].links.split('\n')
      // const total = result.count
      // for(let i in result){
      //   var splitt = result.data[i].links.split('\n')
      //   result.data[i].links = splitt
      // }
      this.isLoading = false
      this.dataSource = new MatTableDataSource<PostModel>(this.PostsData);
      this.paginator.length = result.count;
    });
  }
  openDeleteDialog(element: PostModel): void {
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: '550px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.PostsStoreService.deletePost(element.id).subscribe(result => {
          if (result) {
            this.openSnackBar('Successfully Deleted Record', 'Ok');
            location.reload();
          } else {
            this.openSnackBar('Error While Deleting Record', 'Ok');
          }
        });
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  openDialog(element: PostModel): void {
    this.router.navigate(['dashboard/addPost',element.id],);
    
    // const dialogRef = this.dialog.open(AddEditDialogComponent, {
    //   width: '550px',
    //   data: {
    //     post: element,
    //     isEdit: true,
    //   },
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    //   // this.animal = result;
    // });
  }

  openDialogAdd(): void {
    this.router.navigate(['dashboard/addPost']);
    // const dialogRef = this.dialog.open(AddEditDialogComponent, {
    //   width: '550px',
    //   data: {
    //     post: {
    //       pid: this.latestPID
    //     },
    //     isEdit: false,
    //   },
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    //   // this.animal = result;
    // });
  }

  priority(post,priority){
    this.PostsStoreService.priority(post.id, priority).subscribe((syncStart) => {
      if (!syncStart) {

      }
    });
  }

  changePage(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    
    this.getData();
  }

  planChange(value,postId) {
    let message = '';
    value.checked ? message = 'Are you sure you want to make this post live?' : message = 'Are you sure you want to make this post offline?';
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '550px',
      data: {
        heading: 'Confirm',
        title: message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == ConfirmData.Yes) {
        this.PostsStoreService.goLive(value.checked,postId).subscribe((syncStart) => {
          if (!syncStart) {

          }
        });
      }
    });

  }


  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

}
