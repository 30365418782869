import { Component, OnInit } from '@angular/core';
import { PostsStoreService } from 'src/app/_services/in-word.store.service';
import { UserModel } from 'src/app/view-users/view-users.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ConfirmComponent, ConfirmData } from 'src/app/_shared/confirm/confirm.component';
import { WarningComponent } from 'src/app/_shared/warning/warning.component';
import { Utility } from 'src/app/_helpers/utility';

@Component({
  selector: 'app-mini-dashboard',
  templateUrl: './mini-dashboard.component.html',
  styleUrls: ['./mini-dashboard.component.css']
})
export class MiniDashboardComponent implements OnInit {
  statatics: any[] = [];
  isSync = false;
  public role = Utility.getCookie('role');
  constructor(
    private postsStoreService: PostsStoreService,
    private route: Router,
    public dialog: MatDialog, ) {
    this.postsStoreService.statatics().subscribe(result => {
      this.statatics = result;
      // this.isSync = this.statatics[7];
      // setInterval(()=>{this.updateSync();}, 30000);
    });
  }

  ngOnInit() {
  }

  updateSync() {
    this.postsStoreService.statatics().subscribe(result => {
      this.statatics = result;
      // this.isSync = this.statatics[7];
    });
  }

  navigateApp(n) {
    switch (n) {
      case 1:
        this.route.navigate(['dashboard/Posts']);
        break;
      case 2:
        this.route.navigate(['dashboard/Users']);
        break;
      case 3:
        this.route.navigate(['dashboard/Bookmarks']);
        break;
      case 6:
        this.route.navigate(['dashboard/Offline']);
        break;
      // case 7:
      //   this.syncPosts();
      //   break;
      case 7:
        this.route.navigate(['dashboard/Mails']);
        break;
      case 8:
        this.route.navigate(['dashboard/Plans']);
        break;
      case 9:
        this.route.navigate(['dashboard/Payments']);
        break;
      default:
        alert("This Link Is Not Available !!");
        break;
    }
  }

  // syncPosts() {

  //   const dialogRef = this.dialog.open(ConfirmComponent, {
  //     width: '550px',
  //     data: {
  //       heading: 'Confirm',
  //       title: 'Are you sure you want to sync?',
  //     }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result == ConfirmData.Yes) {
  //       this.isSync = true;
  //       this.postsStoreService.sync().subscribe((syncStart) => {
  //         if (!syncStart) {
  //           this.dialog.open(WarningComponent, {
  //             data: {
  //               heading: 'Sync Process Is Locked?',
  //               title: 'last sync is not completed yet!!!',
  //               buttonText: 'Okay'
  //             }
  //           });
  //         } else {
  //           this.dialog.open(WarningComponent, {
  //             data: {
  //               heading: 'Sync Process Started?',
  //               title: 'You will receive the mail once the process is completed!!!',
  //               buttonText: 'Okay'
  //             }
  //           });
  //         }
  //       });
  //     }
  //   });

  // }

}
