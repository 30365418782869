import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { serviceKeys } from './service-layer.keys';
import { Constants } from '../_helpers/constants';
import {String} from 'typescript-string-operations';
import { ServiceLayer } from './service.layer';
import { Utility } from '../_helpers/utility';
 @Injectable()
 export class DataService {

    private _baseUrl: string;
    protected httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        }),
        params: new HttpParams()
    };

    constructor(http: HttpClient) {
        this._baseUrl = Constants.ROOT_URL;
    }

    get baseUrl() {
        return this._baseUrl;
    }

    public getAPIRoute(baseUrl: string, serviceKey: string, pathParams?: string[], queryString?: string): string {
        let apiRoute = (pathParams && pathParams.length > 0)
        ? `${baseUrl}${String.Format(ServiceLayer.serviceLayerKeys.serviceKeys[serviceKey], ...pathParams)}`
        : `${baseUrl}${ServiceLayer.serviceLayerKeys.serviceKeys[serviceKey]}`;

    if (Utility.isNotNullOrUndefinedOrEmpty(queryString)) {
        if (apiRoute.indexOf('?') > -1) {
            apiRoute = `${apiRoute}&${queryString}`;
        } else {
            apiRoute = `${apiRoute}?${queryString}`;
        }
    }
    return apiRoute;
    }
 }
