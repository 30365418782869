import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { Utility } from "src/app/_helpers/utility";
import { InWordEntry } from "src/app/_models/in-word.dto";
import { PostsStoreService } from "src/app/_services/in-word.store.service";
import { SuggestionModel } from "src/app/view-suggestion/view-suggestion.component";


@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.css']
})
export class SuggestionComponent implements OnInit {
  filteredRackOptions: Observable<string[]>;
  rackOptions: string[] = [];
  addSuggestionForm: FormGroup;
  submitted = false;

  constructor(public dialogRef: MatDialogRef<SuggestionComponent>,
    // private inwordStoreService: InWordStoreService,
    public snackBar: MatSnackBar,
    private PostsStoreService: PostsStoreService,
    @Inject(MAT_DIALOG_DATA) public data: SuggestionModel,
    private fb: FormBuilder,
    public datepipe: DatePipe) { }

  ngOnInit() {
    this.addSuggestionForm = this.fb.group({
      discount: new FormControl("", Validators.required),
      language: new FormControl("Required", Validators.required),
      type: new FormControl("", Validators.required),
      months: new FormControl("", Validators.required),
    });
    if (Utility.isNotNullOrUndefinedOrEmpty(this.data)) {
      this.addSuggestionForm = this.fb.group({
        id: [this.data.id],
        suggestion: [this.data.suggestion],
      });
    } else {
      this.addSuggestionForm = this.fb.group({
        id: [],
        suggestion: [],
      });
    }
  }

  saveRecord() {
    if (this.addSuggestionForm.valid) {
      if (Utility.isNotNullOrUndefinedOrEmpty(this.data)) {
        const suggestionData = this.addSuggestionForm.getRawValue();
        this.PostsStoreService.updatePlanModel(suggestionData).subscribe(
          (response) => {
            this.openSnackBar("Record Updated Successfully", "Ok");
            this.dialogRef.close();
          }
        );
      } else {
        const suggestionData = this.addSuggestionForm.getRawValue();
        this.PostsStoreService.addSuggestion(suggestionData).subscribe((response) => {
          this.openSnackBar("Record Added Successfully", "Ok");
          this.dialogRef.close();
        });
      }
    } else{
      this.submitted = true;
      Utility.markFormGroupTouched(this.addSuggestionForm);
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  get suggestion() {
    return this.addSuggestionForm.get('suggestion');
  }

}
