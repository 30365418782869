export const serviceKeys = {
    getUsers: 'users/',
    verifyPosts: 'admin/updateuser/',
    getPosts: 'alldata/',
    statatics: 'statatics/',
    updatePost: 'updatePost/',
    addPost: 'addPost/',
    deletePost: 'deletePost/',
    notification: 'notification/',
    notificationtoall: 'notificationtoall/',
    mail: 'mail',
    mailtoall: 'mailtoall',
    updateCourt: 'update-court',
    allbookmarks: 'allbookmarks/',
    adminlogin: 'admin-login/',
    updateUser: 'user',
    getOfflinePosts: 'allofflinedata',
    syncPost: 'syncPost',
    goLive: 'enable-post',
    deleteUser: 'deleteuser',
    allmails: 'allmails',
    addPlan: 'addplan',
    getAllPlan: 'getallplans',
    updatePlan: 'updateplan',
    allpayments: 'allpayments',
    getPostsById: 'getPostById',
    addAdmin: 'admin/',
    getAllAdmin: 'getAdmin',
    updateAdmin: 'updateAdmin',
    updateAdminEnabled: 'updateAdminEnable',
    searchPosts: 'search',
    allReferences: 'references',
    planChange: 'addOnLanguage',
    addSuggestion: 'add-suggestion',
    getAllSuggestion: 'get-all-suggestion',
    deleteSuggestion: 'delete-suggestion',
    planUpdate: 'plan-update',
    commentChange: 'update-status',
    comment: 'update-comment',
    priority: 'priority-update',
    verifyUser: 'update-verification'
};
