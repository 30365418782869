import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PostModel, PostModeList } from '../posts/view-posts/view-posts.component';
import { UserModel, Users } from '../view-users/view-users.component';
import { HandleError, HttpErrorHandler } from './error.handle.service';
import { DataService } from './_base.services';
import { BookmarkModel } from '../view-bookmarks/view-bookmarks.component';
import { Utility } from '../_helpers/utility';
import { MailModel } from '../view-mails/view-mails.component';
import { PlanModel } from '../view-plans/view-plans.component';
import { PlanModelList } from '../view-plans/view-plans.component';

import { AdminModel } from '../view-admins/view-admins.component';
import { AdminModelList } from '../view-admins/view-admins.component';
import { PaymentModel } from '../view-payments/view-payments.component';
import { ReferenceModel } from '../view-references/view-references.component';
import { SuggestionModel } from '../view-suggestion/view-suggestion.component';

@Injectable()
export class Postservice extends DataService {
    private handleError: HandleError;

    constructor(private http: HttpClient,
        private httpErrorHandler: HttpErrorHandler) {
        super(http);
        this.handleError = httpErrorHandler.createHandleError('test');
    }

    verifyPosts(id: String): Observable<boolean> {
        const url = this.getAPIRoute(this.baseUrl, 'verifyPosts');
        var data = {
            "userId": id,
            "isSemiVerified": true,
        }
        return this.http.post<any>(url, data).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    login(data): Observable<boolean> {
        const url = this.getAPIRoute(this.baseUrl, 'adminlogin');
        return this.http.post<any>(url, data).pipe(
            map(res => {
                if(res['responseCode'] == 2000){
                Utility.storeCookie('token',res['token']);
                Utility.storeCookie('role',res['result']['role']);
                }
                return res['responseCode'] == 2000
            }));
    }


    deletePost(post: String): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'deletePost');
        var data =
        {
        //     "username": "bauktion",
        //     "password": "bauktion@2019",
            "data": post,
        }
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.post<any>(url, data,{headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    deleteSuggestion(suggestion: String): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'deleteSuggestion')+"?suggestionId="+suggestion;
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.delete<any>(url,{headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    deactiveUser(uid: String, value: boolean): Observable<boolean> {
        const token = Utility.getCookie('token');
        
        const url = this.getAPIRoute(this.baseUrl, 'deleteUser');
        var data = {
            "data": uid,
            "value": value,
        }
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.post<any>(url, data,{headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    planChange(uid: String, value: boolean, language: String): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'planChange');
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.put<any>(url, {"userId":uid, "status":value, "language":language},{ headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    verifyUser(uid: String, verify: boolean): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'verifyUser');
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.put<any>(url, {"userId":uid, "isMobileVerified":verify},{ headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    commentChange(referenceId: String, status: Boolean): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'commentChange');
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.put<any>(url, {"referenceId":referenceId, "status":status},{ headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    comment(referenceId: String, comments:String): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'comment');
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.put<any>(url, {"referenceId":referenceId, "comment":comments},{ headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    priority(postId: String, priority:Number): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'priority');
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.put<any>(url, {"postId":postId, "priority":priority},{ headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    planUpdate(uid: String, planType: String): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'planUpdate');
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.put<any>(url, {"userId":uid,"planType":planType},{ headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    courtUpdate(uid: String, courtType: String): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'updateCourt');
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.put<any>(url, {"userId":uid,"courtType":courtType},{ headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    sync(): Observable<boolean> {
        const url = this.getAPIRoute(this.baseUrl, 'syncPost');
        var data = {
            "username": "bauktion",
            "password": "bauktion@2019",
        }
        return this.http.post<any>(url, data).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    goLive(enable,postId): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'goLive');
        var data = {
            "enabled": enable,
            "postId": postId.id
        }
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.put<any>(url, data,{headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    notification(messageModel): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'notification');
        var data = {
            "username": "bauktion",
            "password": "bauktion@2019",
            "data": messageModel,
        }
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.post<any>(url, data,{headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }
    mail(messageModel): Observable<boolean> {
        const url = this.getAPIRoute(this.baseUrl, 'mail');
        var data = {
            "username": "bauktion",
            "password": "bauktion@2019",
            "data": messageModel,
        }
        return this.http.post<any>(url, data).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    updatePlan(userDateData): Observable<boolean> {
        const token = Utility.getCookie('token');
        
        const url = this.getAPIRoute(this.baseUrl, 'updateUser');
        var data = 
        {
            "data": userDateData,
        }
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.put<any>(url, data,{headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    updateAdmin(userDateData): Observable<boolean> {
        const token = Utility.getCookie('token');
        
        const url = this.getAPIRoute(this.baseUrl, 'updateAdmin');
        var data = userDateData
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.put<any>(url, data,{headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    updatePlanModel(planData): Observable<boolean> {
        const token = Utility.getCookie('token');
        
        const url = this.getAPIRoute(this.baseUrl, 'updatePlan');
        var data = 
        {
            "data": planData,
        }
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.put<any>(url, data,{headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    updateAdminModel(adminData): Observable<boolean> {
        const token = Utility.getCookie('token');
        
        const url = this.getAPIRoute(this.baseUrl, 'updateAdmin');
        var data = adminData
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.put<any>(url, data,{headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    updateAdminEnabled(adminData): Observable<boolean> {
        const token = Utility.getCookie('token');
        
        const url = this.getAPIRoute(this.baseUrl, 'updateAdminEnabled');
        var data = {"id":adminData.id, "enabled": adminData.enabled};
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.put<any>(url, data,{headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    notificationToAll(messageModel): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'notificationtoall');
        var data = {
            "username": "bauktion",
            "password": "bauktion@2019",
            "data": messageModel,
        }
        return this.http.post<any>(url, data).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    mailToAll(messageModel): Observable<boolean> {
        const url = this.getAPIRoute(this.baseUrl, 'mailtoall');
        var data = {
            "username": "bauktion",
            "password": "bauktion@2019",
            "data": messageModel,
        }
        return this.http.post<any>(url, data).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    updatePost(post: PostModel): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'updatePost');
        var data = {
            "data": post,
        }
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.put<any>(url, data,{headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }
    addPost(post: PostModel): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'addPost');
        var data = post
        const headers = new HttpHeaders({'token':`${token}`,'Access-Control-Allow-Origin': '*'});
        return this.http.post<any>(url, data,{headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    addPlan(plan: PlanModel): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'addPlan');
        var data = 
        {
            "data": plan,
        }
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.post<any>(url, data,{headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    addSuggestion(suggestion: SuggestionModel): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'addSuggestion');
        var data = suggestion
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.post<any>(url, data,{headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }

    addAdmin(admin: AdminModel): Observable<boolean> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'addAdmin');
        var data = admin
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.post<any>(url, data,{headers: headers}).pipe(
            map(res => {
                return res['responseCode'] == 2000
            }));
    }


    getAllOfflinePosts(pageSize: number, pageIndex: number): Observable<PostModeList> {
        
        const url = this.getAPIRoute(this.baseUrl, 'getOfflinePosts');
        var data = {
            "username": "bauktion",
            "password": "bauktion@2019",
            "pageSize": pageSize,
            "pageIndex": pageIndex,
        }
        return this.http.post<any>(url, data)
            .pipe(
                map(res => {
                    
                    let dataAll: PostModeList = {
                        count: 0,
                        data: [],
                        length: 0
                    };
                    let doctorData: PostModel[] = [];
                    var Posts = res['result'][0]['totalData'];
                    Posts.forEach((element, index) => {
                        let doctor: PostModel = {
                            no: (index + 1) + ((pageIndex - 1) * pageSize),
                            id: element['_id'],
                            isBookmarked: false,
                            postType: element['postType'],
                            courtType: element['courtType'],
                            courtSubType: element['courtSubType'],
                            inFavourOf: element['inFavourOf'],
                            priority: element['priority'],
                            appelentName: element['appelentName'],
                            respondentName: element['respondentName'],
                            judges: element['judges'],
                            fullJudgement: element['fullJudgement'],
                            type: element['type'],
                            decidedDate: element['decidedDate'],
                            importantPoints: element['importantPoints'],
                            importantPointsHindi: element['importantPointsHindi'],
                            importantPointsMarathi: element['importantPointsMarathi'],
                            importantPointsGujrati: element['importantPointsGujrati'],
                            headNote: element['headNote'],
                            headNoteHindi: element['headNoteHindi'],
                            headNoteMarathi: element['headNoteMarathi'],
                            headNoteGujrati: element['headNoteGujrati'],
                            result: element['result'],
                            resultHindi: element['resultHindi'],
                            resultMarathi: element['resultMarathi'],
                            resultGujrati: element['resultGujrati'],
                            caseReffered: element['caseReffered'],
                            actsReffered: element['actsReffered'],
                            links: element['links'],
                            enabled: element['enabled'],
                            pid: element['pid'],
                        }
                        doctorData.push(doctor);
                    });
                    dataAll.data = doctorData;
                    dataAll.count = res['result'][0]["totalCount"][0]["count"];
                    return dataAll;
                }),
                catchError(this.handleError<PostModeList>('test'))
            );
    }

    getPostById(id){
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'getPostsById');
        var data = {
            "postId": id
        }
        const headers = new HttpHeaders({'token':`${token}`});
        var doctor: PostModel ;
        return this.http.post<any>(url, data,{headers: headers})
            .pipe(
                map(res => {
                    var element = res['result'];
                        doctor = {
                            no: '1',
                            id: element['_id'],
                            isBookmarked: false,
                            appelentName: element['appelentName'],
                            respondentName: element['respondentName'],
                            judges: element['judges'],
                            type: element['type'],
                            postType: element['postType'],
                            inFavourOf: element['inFavourOf'],
                            courtType: element['courtType'],
                            courtSubType: element['courtSubType'],
                            priority: element['priority'],
                            fullJudgement: element['fullJudgement'],
                            decidedDate: element['decidedDate'],
                            importantPoints: element['importantPoints'],
                            importantPointsHindi: element['importantPointsHindi'],
                            importantPointsMarathi: element['importantPointsMarathi'],
                            importantPointsGujrati: element['importantPointsGujrati'],
                            headNote: element['headNote'],
                            headNoteHindi: element['headNoteHindi'],
                            headNoteMarathi: element['headNoteMarathi'],
                            headNoteGujrati: element['headNoteGujrati'],
                            result: element['result'],
                            resultHindi: element['resultHindi'],
                            resultMarathi: element['resultMarathi'],
                            resultGujrati: element['resultGujrati'],
                            caseReffered: element['caseReffered'],
                            actsReffered: element['actsReffered'],
                            links: element['links'],
                            enabled: element['enabled'],
                            pid: element['pid'],
                        };
                    return doctor;
                }),
                catchError(this.handleError<PostModeList>('test'))
            );
    }

    getAllPosts(pageSize: number, pageIndex: number,date: String, searchText: String): Observable<PostModeList> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'getPosts');
        var data = {
            "pageSize": pageSize,
            "pageIndex": pageIndex,
            "date": date,
            "searchText": searchText
        }
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.post<any>(url, data,{headers: headers})
            .pipe(
                map(res => {
                    
                    let dataAll: PostModeList = {
                        count: 0,
                        data: [],
                        length: 0
                    };
                    let doctorData: PostModel[] = [];
                    var Posts = res['result'][0]['totalData'];
                    Posts.forEach((element, index) => {
                        let doctor: PostModel = {
                            no: (index + 1) + ((pageIndex - 1) * pageSize),
                            id: element['_id'],
                            postType: element['postType'],
                            courtType: element['courtType'],
                            inFavourOf: element['inFavourOf'],
                            courtSubType: element['courtSubType'],
                            isBookmarked: false,
                            appelentName: element['appelentName'],
                            priority: element['priority'],
                            respondentName: element['respondentName'],
                            judges: element['judges'],
                            fullJudgement: element['fullJudgement'],
                            type: element['type'],
                            decidedDate: element['decidedDate'],
                            importantPoints: element['importantPoints'],
                            importantPointsHindi: element['importantPointsHindi'],
                            importantPointsMarathi: element['importantPointsMarathi'],
                            importantPointsGujrati: element['importantPointsGujrati'],
                            headNote: element['headNote'],
                            headNoteHindi: element['headNoteHindi'],
                            headNoteMarathi: element['headNoteMarathi'],
                            headNoteGujrati: element['headNoteGujrati'],
                            result: element['result'],
                            resultHindi: element['resultHindi'],
                            resultMarathi: element['resultMarathi'],
                            resultGujrati: element['resultGujrati'],
                            caseReffered: element['caseReffered'],
                            actsReffered: element['actsReffered'],
                            links: element['links'],
                            pid: element['pid'],
                            enabled: element['enabled'],
                        }
                        doctorData.push(doctor);
                    });
                    dataAll.data = doctorData;
                    dataAll.count = res['result'][0]["totalCount"][0]["count"];
                    return dataAll;
                }),
                catchError(this.handleError<PostModeList>('test'))
            );
    }

    searchPosts(pageSize: number, pageIndex: number, searchText: String): Observable<PostModeList> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'searchPosts');
        var data = {
            "pageSize": pageSize,
            "pageIndex": pageIndex,
            "search": searchText,
        }
        const headers = new HttpHeaders({'token':`${token}`});
        const params = new HttpParams().append('pageSize', pageSize.toString()).append('pageIndex', pageIndex.toString()).append('search', searchText.toString());
        return this.http.get<any>(url,{headers: headers, params: params})
            .pipe(
                map(res => {
                    
                    let dataAll: PostModeList = {
                        count: 0,
                        data: [],
                        length: 0
                    };
                    let doctorData: PostModel[] = [];
                    var Posts = res['result'];
                    Posts.forEach((element, index) => {
                        let doctor: PostModel = {
                            no: (index + 1) + ((pageIndex - 1) * pageSize),
                            id: element['_id'],
                            isBookmarked: false,
                            postType: element['postType'],
                            courtType: element['courtType'],
                            inFavourOf: element['inFavourOf'],
                            courtSubType: element['courtSubType'],
                            appelentName: element['appelentName'],
                            respondentName: element['respondentName'],
                            judges: element['judges'],
                            priority: element['priority'],
                            fullJudgement: element['fullJudgement'],
                            type: element['type'],
                            decidedDate: element['decidedDate'],
                            importantPoints: element['importantPoints'],
                            importantPointsHindi: element['importantPointsHindi'],
                            importantPointsMarathi: element['importantPointsMarathi'],
                            importantPointsGujrati: element['importantPointsGujrati'],
                            headNote: element['headNote'],
                            headNoteHindi: element['headNoteHindi'],
                            headNoteMarathi: element['headNoteMarathi'],
                            headNoteGujrati: element['headNoteGujrati'],
                            result: element['result'],
                            resultHindi: element['resultHindi'],
                            resultMarathi: element['resultMarathi'],
                            resultGujrati: element['resultGujrati'],
                            caseReffered: element['caseReffered'],
                            actsReffered: element['actsReffered'],
                            links: element['links'],
                            pid: element['pid'],
                            enabled: element['enabled'],
                        }
                        doctorData.push(doctor);
                    });
                    dataAll.data = doctorData;
                    dataAll.count = res['result'][0]
                    return dataAll;
                }),
                catchError(this.handleError<PostModeList>('test'))
            );
    }

    statatics(): Observable<Number[]> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'statatics');
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.get<any>(url,{headers: headers})
            .pipe(
                map(res => {
                    
                    let statics: any[] = [];
                    statics.push(res.result[0].datas[0].count);
                    statics.push(res.result[0].users[0].count);
                    statics.push(res.result[0].bookmarks[0].count);
                    statics.push(res.counts.sms);
                    // statics.push(res.counts.lastId);
                    // statics.push(res.counts.total);
                    // if (Utility.isNotNullOrUndefinedOrEmpty(res.result[0].offline[0]) && Utility.isNotNullOrUndefinedOrEmpty(res.result[0].offline[0].count)) {
                    //     statics.push(res.result[0].offline[0].count)
                    // } else {
                    //     statics.push(0);
                    // }
                    // statics.push(res.counts.sync);
                    // statics.push(res.result[0].mails.count);

                    if (Utility.isNotNullOrUndefinedOrEmpty(res.result[0].plans[0]) && Utility.isNotNullOrUndefinedOrEmpty(res.result[0].plans[0].count)) {
                        statics.push(res.result[0].plans[0].count)
                    } else {
                        statics.push(0);
                    }

                    if (Utility.isNotNullOrUndefinedOrEmpty(res.result[0].payments[0]) && Utility.isNotNullOrUndefinedOrEmpty(res.result[0].payments[0].count)) {
                        statics.push(res.result[0].payments[0].count)
                    } else {
                        statics.push(0);
                    }
                    statics.push(res.result[0].counts[0].totalCivil);
                    statics.push(res.result[0].counts[0].totalCriminal);
                    return statics;
                }),
                catchError(this.handleError<number[]>('test'))
            );
    }
    getAllMails(): Observable<MailModel[]> {
        const url = this.getAPIRoute(this.baseUrl, 'allmails');
        var data = {
            "username": "bauktion",
            "password": "bauktion@2019",
        }
        return this.http.post<any>(url, data)
            .pipe(
                map(res => {
                    
                    let mailsData: MailModel[] = [];
                    var mails = res['result'];
                    mails.forEach((element, index) => {
                        
                        let mailModel: MailModel;
                        mailModel = {
                            no: (index + 1).toString(),
                            to: element['to'],
                            from: element['from'],
                            mailId: element['mailId'],
                            message: element['message'],
                            subject: element['subject']
                        };

                        mailsData.push(mailModel);
                    });
                    return mailsData.reverse();
                }),
                catchError(this.handleError<MailModel[]>('test'))
            );
    }
    getAllPayments(status: Number): Observable<PaymentModel[]> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'allpayments');
        var data = {
            "status": status
        }
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.post<any>(url, data,{headers: headers})
            .pipe(
                map(res => {
                    
                    let paymentsData: PaymentModel[] = [];
                    var payments = res['result'];
                    payments.forEach((element, index) => {
                        let paymentModel: PaymentModel;
                        let users = {
                            fullName: element['user']['fullName'],
                            position: 0,
                            // email: element['user']['email'],
                            city: element['user']['city'],
                            state: element['user']['state'],
                            phoneNumber: element['user']['phoneNumber'],
                            isMobileVerified: true,
                            isGujarati: element['user']['isGujarati'],
                            isHindi: element['user']['isHindi'],
                            isMarathi: element['user']['isMarathi'],
                            planType: element['user']['planType'],
                            courtType: element['user']['courtType'],
                            reference: element['user']['reference'],
                            agentId: element['user']['agentId'],
                            // isEmailVerified: true,
                            id: 0,
                            isFreePlanUsed: true,
                            isPlanActivied: true,
                            imei: 'abcd',
                        }
                        paymentModel = {
                            no: (index + 1).toString(),
                            planId: element['planId'],
                            dateCreated: element['dateCreated'],
                            txtAmount: element['txtAmount'],
                            discountValue: element['discountValue'],
                            txtId: element['txtId'],
                            userId: element['userId'],
                            agentId: element['agentId'],
                            user: users
                        };

                        paymentsData.push(paymentModel);
                    });
                    return paymentsData.reverse();
                }),
                catchError(this.handleError<PaymentModel[]>('test'))
            );
    }

    getAllPlans(): Observable<PlanModel[]> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute('https://api.dailylawdigest.com/v2/', 'getAllPlan');
        var data = {
        }
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.post<any>(url, data,{headers: headers})
            .pipe(
                map(res => {
                    
                    let plansData: PlanModel[] = [];
                    var plans = res['result'];
                    plans.forEach((element, index) => {
                        let planModel: PlanModel;
                        planModel = {
                            no: (index + 1).toString(),
                            id: element['_id'],
                            language: element['language'],
                            courtType: element['courtType'],
                            type: element['type'],
                            months: element['months'],
                            discount: element['discount'],
                            total: element['total'],
                            cost: element['cost'],
                            order: element['order'],
                            planExpiry: element['planExpiry'],
                            enabled: element['enabled']
                        };

                        plansData.push(planModel);
                    });
                    
                    return plansData.reverse();
                }),
                catchError(this.handleError<PlanModel[]>('test'))
            );
    }

    getAllSuggestion(): Observable<SuggestionModel[]> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'getAllSuggestion');
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.get<any>(url, {headers: headers})
            .pipe(
                map(res => {   
                    let suggestionData: SuggestionModel[] = [];
                    var suggestion = res['result'];
                    suggestion.forEach((element, index) => {
                        let suggestionModel: SuggestionModel;
                        suggestionModel = {
                            id: element['_id'],
                            suggestion: element['suggestion'],
                        };

                        suggestionData.push(suggestionModel);
                    });
                    
                    return suggestionData.reverse();
                }),
                catchError(this.handleError<SuggestionModel[]>('test'))
            );
    }

    getAllAdmins(): Observable<AdminModel[]> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'getAllAdmin');
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.get<any>(url,{headers: headers})
            .pipe(
                map(res => {
                    
                    let adminsData: AdminModel[] = [];
                    var admins = res['result'];
                    admins.forEach((element, index) => {
                        let adminModel: AdminModel;
                        adminModel = {
                            no: (index + 1).toString(),
                            id: element['_id'],
                            fullName: element['fullName'],
                            email: element['email'],
                            agentId: element['agentId'],
                            discountValue: element['discountValue'],
                            mobile: element['mobile'],
                            gender: element['gender'],
                            password: element['password'],
                            role: element['role'],
                            enabled: element['enabled']
                        };

                        adminsData.push(adminModel);
                    });
                    
                    return adminsData.reverse();
                }),
                catchError(this.handleError<AdminModel[]>('test'))
            );
    }


    getAllBookmars(pageSize: number, pageIndex: number): Observable<BookmarkModel[]> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'allbookmarks') + '?pageSize=' +pageSize + '&pageIndex='+pageIndex;
        var data = {
            "pageSize": pageSize,
            "pageIndex": pageIndex,
        }
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.post<any>(url,data,{headers: headers})
            .pipe(
                map(res => {
                    
                    let bookmarkData: BookmarkModel[] = [];
                    var bookmarks = res['result'];
                    bookmarks.forEach((element, index) => {
                        
                        let bookmarkModel: BookmarkModel;
                        let user: UserModel = {
                            state: element['user']['state'],
                            isGujarati: element['user']['isGujarati'],
                            isHindi: element['user']['isHindi'],
                            isMarathi: element['user']['isMarathi'],
                            planType: element['user']['planType'],
                            courtType: element['user']['courtType'],
                            city: element['user']['city'],
                            fullName: element['user']['fullName'],
                            // isEmailVerified: element['user']['isEmailVerified'],
                            isMobileVerified: element['user']['isMobileVerified'],
                            phoneNumber: element['user']['phoneNumber'],
                            reference: element['user']['reference'],
                            agentId: element['user']['agentId'],
                            isFreePlanUsed: element['user']['isFreePlanUsed'],
                            isPlanActivied: element['user']['isPlanActivied'],
                            planStartDate: element['user']['planStartDate'],
                            position: index,
                            imei: element['user']['imei'],
                            planExpiryDate: element['user']['planExpiryDate'],
                            id: element['user']['_id']
                        }

                        let post: PostModel = {
                            no: index,
                            id: element['post']['_id'],
                            isBookmarked: false,
                            postType: element['postType'],
                            inFavourOf: element['inFavourOf'],
                            courtType: element['courtType'],
                            courtSubType: element['courtSubType'],
                            appelentName: element['post']['appelentName'],
                            respondentName: element['post']['respondentName'],
                            priority: element['post']['priority'],
                            judges: element['post']['judges'],
                            fullJudgement: element['post']['fullJudgement'],
                            type: element['post']['type'],
                            decidedDate: element['post']['decidedDate'],
                            importantPoints: element['post']['importantPoints'],
                            importantPointsHindi: element['post']['importantPointsHindi'],
                            importantPointsMarathi: element['post']['importantPointsMarathi'],
                            importantPointsGujrati: element['post']['importantPointsGujrati'],
                            headNote: element['post']['headNote'],
                            headNoteHindi: element['post']['headNoteHindi'],
                            headNoteMarathi: element['post']['headNoteMarathi'],
                            headNoteGujrati: element['post']['headNoteGujrati'],
                            result: element['post']['result'],
                            resultHindi: element['resultHindi'],
                            resultMarathi: element['resultMarathi'],
                            resultGujrati: element['resultGujrati'],
                            caseReffered: element['post']['caseReffered'],
                            actsReffered: element['post']['actsReffered'],
                            links: element['post']['links'],
                            pid: element['post']['pid'],
                            enabled: element['enabled'],
                        }

                        bookmarkModel = {
                            user: user,
                            post: post
                        };

                        bookmarkData.push(bookmarkModel);
                    });
                    return bookmarkData.reverse();
                }),
                catchError(this.handleError<BookmarkModel[]>('test'))
            );
    }

    getAllReferences(pageSize: number, pageIndex: number): Observable<ReferenceModel[]> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'allReferences') + '?pageSize=' +pageSize + '&pageIndex='+pageIndex;
        var data = {
            "pageSize": pageSize,
            "pageIndex": pageIndex,
        }
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.get<any>(url,{headers: headers})
            .pipe(
                map(res => {
                    
                    let referenceData: ReferenceModel[] = [];
                    var bookmarks = res['result'];
                    bookmarks.forEach((element, index) => {
                        
                        let user: UserModel = {
                            state: element['users'][0]['state'],
                            city: element['users'][0]['city'],
                            fullName: element['users'][0]['fullName'],
                            planStartDate: element['users'][0]['planStartDate'],
                            // isEmailVerified: element['user']['isEmailVerified'],
                            isMobileVerified: element['users'][0]['isMobileVerified'],
                            phoneNumber: element['users'][0]['phoneNumber'],
                            reference: element['users'][0]['reference'],
                            agentId: element['users'][0]['agentId'],
                            isFreePlanUsed: element['users'][0]['isFreePlanUsed'],
                            isPlanActivied: element['users'][0]['isPlanActivied'],
                            position: index,
                            isGujarati: element['users'][0]['isGujarati'],
                            isHindi: element['users'][0]['isHindi'],
                            isMarathi: element['users'][0]['isMarathi'],
                            planType: element['users'][0]['planType'],
                            courtType: element['users'][0]['courtType'],
                            imei: element['users'][0]['imei'],
                            planExpiryDate: element['users'][0]['planExpiryDate'],
                            id: element['users'][0]['_id']
                        }

                        let referenceModel = {
                            user: user,
                            id: element['_id'],
                            mobile: element['mobile'],
                            name: element['name'],
                            comment: element['comment'],
                            status: element['status']
                        };
                        referenceData.push(referenceModel);
                    });
                    return referenceData.reverse();
                }),
                catchError(this.handleError<ReferenceModel[]>('test'))
            );
    }

    getAllUsers(pageSize: number, pageIndex: number, searchText: String): Observable<Users> {
        const token = Utility.getCookie('token');
        const url = this.getAPIRoute(this.baseUrl, 'getUsers');
        var data = {
            "pageSize": pageSize,
            "pageIndex": pageIndex,
            "searchText": searchText,
        }
        const headers = new HttpHeaders({'token':`${token}`});
        return this.http.post<any>(url, data,{headers: headers})
            .pipe(
                map(res => {
                    
                    let doctorData: UserModel[] = [];
                    var Posts = res['users'];
                    Posts.forEach((element, index) => {
                        let doctor: UserModel = {
                            state: element['state'],
                            city: element['city'],
                            fullName: element['fullName'],
                            // isEmailVerified: element['isEmailVerified'],
                            isMobileVerified: element['isMobileVerified'],
                            phoneNumber: element['phoneNumber'],
                            reference: element['reference'],
                            agentId: element['agentId'],
                            isGujarati: element['isGujarati'],
                            isHindi: element['isHindi'],
                            isMarathi: element['isMarathi'],
                            planType: element['planType'],
                            courtType: element['courtType'],
                            isFreePlanUsed: element['isFreePlanUsed'],
                            planStartDate: element['planStartDate'],
                            isPlanActivied: element['isPlanActivied'],
                            position: (pageSize*(pageIndex-1))+(index+1),
                            imei: element['imei'],
                            planExpiryDate: element['planExpiryDate'],
                            isDeleted: element['isDeleted'],
                            id: element['_id']
                        }
                        doctorData.push(doctor);
                    });
                    let users:Users = {
                        UserModel: [],
                        length : 0
                    };
                    users.UserModel = doctorData;
                    users.length = res['lenght'];
                    return users;
                }),
                catchError(this.handleError<Users>('test'))
            );
    }
}
