import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Postservice } from './in-word.services';
import { UserModel, Users } from '../view-users/view-users.component';
import { PostModel, PostModeList } from '../posts/view-posts/view-posts.component';
import { BookmarkModel } from '../view-bookmarks/view-bookmarks.component';
import { MailModel } from '../view-mails/view-mails.component';
import { PlanModel } from '../view-plans/view-plans.component';
import { PaymentModel } from '../view-payments/view-payments.component';
import { AdminModel } from '../view-admins/view-admins.component';
import { ReferenceModel } from '../view-references/view-references.component';
import { SuggestionModel } from '../view-suggestion/view-suggestion.component';

@Injectable()
export class PostsStoreService {

    constructor(private Postservice: Postservice) {

    }


    getAllUsers(pageSize: number, pageIndex: number, searchText:String): Observable<Users> {
        return this.Postservice.getAllUsers(pageSize, pageIndex,searchText)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    searchPosts(pageSize: number, pageIndex: number, searchText:String): Observable<PostModeList> {
        return this.Postservice.searchPosts(pageSize, pageIndex,searchText)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    getPostById(id){
        return this.Postservice.getPostById(id)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    getAllBookmars(pageSize: number, pageIndex: number): Observable<BookmarkModel[]> {
        return this.Postservice.getAllBookmars(pageSize, pageIndex)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    getAllReferences(pageSize: number, pageIndex: number): Observable<ReferenceModel[]> {
        return this.Postservice.getAllReferences(pageSize, pageIndex)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    getAllPlans(): Observable<PlanModel[]> {
        return this.Postservice.getAllPlans()
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    getAllSuggestion(): Observable<SuggestionModel[]> {
        return this.Postservice.getAllSuggestion()
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    getAllAdmins(): Observable<AdminModel[]> {
        return this.Postservice.getAllAdmins()
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    getAllPayments(status: Number): Observable<PaymentModel[]> {
        return this.Postservice.getAllPayments(status)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    getAllMails(): Observable<MailModel[]> {
        return this.Postservice.getAllMails()
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    statatics(): Observable<Number[]> {
        return this.Postservice.statatics()
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    getAllPosts(pageSize: number, pageIndex: number,date: String, searchText: String): Observable<PostModeList> {
        return this.Postservice.getAllPosts(pageSize, pageIndex,date,searchText)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    getAllOfflinePosts(pageSize: number, pageIndex: number): Observable<PostModeList> {
        return this.Postservice.getAllOfflinePosts(pageSize, pageIndex)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    deletePost(post: String): Observable<Boolean> {
        return this.Postservice.deletePost(post).pipe(map(result => {
            return result;
        }))
    }

    deleteSuggestion(suggestion: String): Observable<Boolean> {
        return this.Postservice.deleteSuggestion(suggestion).pipe(map(result => {
            return result;
        }))
    }

    deactiveUser(uid: String,value:boolean): Observable<Boolean> {
        return this.Postservice.deactiveUser(uid,value).pipe(map(result => {
            return result;
        }))
    }

    commentChange(referenceId: String, status: Boolean): Observable<Boolean> {
        return this.Postservice.commentChange(referenceId,status).pipe(map(result => {
            return result;
        }))
    }

    comment(referenceId: String, comment: String): Observable<Boolean> {
        return this.Postservice.comment(referenceId,comment).pipe(map(result => {
            return result;
        }))
    }

    priority(postId: String, priority: Number): Observable<Boolean> {
        return this.Postservice.priority(postId,priority).pipe(map(result => {
            return result;
        }))
    }

    planChange(uid: String,value:boolean,language: String): Observable<Boolean> {
        return this.Postservice.planChange(uid,value,language).pipe(map(result => {
            return result;
        }))
    }

    verifyUser(uid: String,verify:boolean): Observable<Boolean> {
        return this.Postservice.verifyUser(uid,verify).pipe(map(result => {
            return result;
        }))
    }

    planUpdate(uid: String,planType: String): Observable<Boolean> {
        return this.Postservice.planUpdate(uid,planType).pipe(map(result => {
            return result;
        }))
    }

    courtUpdate(uid: String,courtType: String): Observable<Boolean> {
        return this.Postservice.courtUpdate(uid,courtType).pipe(map(result => {
            return result;
        }))
    }

    sync(): Observable<Boolean> {
        return this.Postservice.sync().pipe(map(result => {
            return result;
        }))
    }

    goLive(enable,postId):Observable<Boolean> {
        return this.Postservice.goLive(enable,postId).pipe(map(result => {
            return result;
        }))
    }

    login(data): Observable<Boolean> {
        return this.Postservice.login(data).pipe(map(result => {
            return result;
        }))
    }

    

    notification(messageData): Observable<Boolean> {
        return this.Postservice.notification(messageData).pipe(map(result => {
            return result;
        }))
    }

    mail(messageData): Observable<Boolean> {
        return this.Postservice.mail(messageData).pipe(map(result => {
            return result;
        }))
    }

    updatePlan(userDateData): Observable<Boolean>{
        return this.Postservice.updatePlan(userDateData).pipe(map(result => {
            return result;
        }))
    }

    updatePlanModel(PlanData): Observable<Boolean>{
        return this.Postservice.updatePlanModel(PlanData).pipe(map(result => {
            return result;
        }))
    }

    updateAdmin(userDateData): Observable<Boolean>{
        return this.Postservice.updateAdmin(userDateData).pipe(map(result => {
            return result;
        }))
    }

    updateAdminModel(AdminData): Observable<Boolean>{
        return this.Postservice.updateAdminModel(AdminData).pipe(map(result => {
            return result;
        }))
    }

    updateAdminEnabled(AdminData): Observable<Boolean>{
        return this.Postservice.updateAdminEnabled(AdminData).pipe(map(result => {
            return result;
        }))
    }

    notificationToAll(messageData): Observable<Boolean> {
        return this.Postservice.notificationToAll(messageData).pipe(map(result => {
            return result;
        }))
    }

    mailToAll(messageData): Observable<Boolean> {
        return this.Postservice.mailToAll(messageData).pipe(map(result => {
            return result;
        }))
    }

    updatePost(post: PostModel): Observable<Boolean> {
        return this.Postservice.updatePost(post).pipe(map(result => {
            return result;
        }))
    }
    addPost(post: PostModel): Observable<Boolean> {
        return this.Postservice.addPost(post).pipe(map(result => {
            return result;
        }))
    }

    addPlan(plan: PlanModel): Observable<Boolean> {
        return this.Postservice.addPlan(plan).pipe(map(result => {
            return result;
        }))
    }

    addSuggestion(suggestion: SuggestionModel): Observable<Boolean> {
        return this.Postservice.addSuggestion(suggestion).pipe(map(result => {
            return result;
        }))
    }

    addAdmin(admin: AdminModel): Observable<Boolean> {
        return this.Postservice.addAdmin(admin).pipe(map(result => {
            return result;
        }))
    }

    verifyPosts(id: String): Observable<Boolean> {
        return this.Postservice.verifyPosts(id).pipe(map(result => {
            return result;
        }))
    }
}
