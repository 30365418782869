import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import HttpStatusCode from '../_models/enum/http-status-code.enum';
import { MessageService } from './message.service';

/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError =
 <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

/** Handles HttpClient errors */
@Injectable()
export class HttpErrorHandler {
 constructor(private messageService: MessageService) { }

 /** Create curried handleError function that already knows the service name */
 createHandleError = (serviceName = '') => <T>
   (operation = 'operation', result = {} as T) => this.handleError(serviceName, operation, result)

 /**
  * Returns a function that handles Http operation failures.
  * This error handler lets the app continue to run as if no error occurred.
  * @param serviceName = name of the data service that attempted the operation
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
 handleError<T>(serviceName = '', operation = 'operation', result = {} as T) {

   return (error: HttpErrorResponse): Observable<T> => {
     // TODO: send the error to remote logging infrastructure

     const message = (error.error instanceof ErrorEvent) ?
       error.error.message :
       `server returned code ${error.status} with body "${error.error}"`;

     // TODO: better job of transforming error for user consumption
     this.messageService.add(`${serviceName}: ${operation} failed: ${message}`);

     if (error instanceof HttpErrorResponse &&
       error.status === HttpStatusCode.UNAUTHORIZED) {
       // TODO: need to fix unit test with mock service before having below change
       // window.location.href = '/login';
       return EMPTY;
     } else if (error.error != null) {
       return throwError(error);
     }

     // if no error details found, return EMPTY to keep the app keep running by returning a safe result.
     return EMPTY;
   };

 }
}
