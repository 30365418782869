import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Utility } from 'src/app/_helpers/utility';
import { InWordEntry } from 'src/app/_models/in-word.dto';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  filteredRackOptions: Observable<string[]>;
  rackOptions: string[] = [];
  editForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<EditComponent>,
    // private inwordStoreService: InWordStoreService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: InWordEntry,
    private fb: FormBuilder) { }

  ngOnInit() {

    this.editForm = this.fb.group({
      place: [this.data.place, Validators.required],
    });

    this.filteredRackOptions = this.editForm.controls.place.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          return this._filter(value, this.rackOptions)
        }
        )
      );

    // this.inwordStoreService.inWord$.subscribe(result => {
    //   result.forEach(inWord => {
    //     if (this.rackOptions.includes(inWord.place.toString()) === false) {
    //       this.rackOptions.push(inWord.place.toString());
    //     }
    //   });
    // });
  }

  // saveRecord(){
  //   const updatedData = this.data;
  //   updatedData.place = this.editForm.controls.place.value;
  //   this.inwordStoreService.updateInwordEntry(updatedData).subscribe(response => {
  //     this.openSnackBar('Record Updated Successfully', 'Ok');
  //     this.dialogRef.close();
  //   });
  // }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


  private _filter(value: string, filredArray: string[]): string[] {
    let filterValue = '';
    if (Utility.isNullOrUndefinedOrEmpty(value)) {
      filterValue = '';
    } else {
      filterValue = value.toLowerCase();
    }
    return filredArray.filter(option => option.toLowerCase().includes(filterValue));
  }

}
