import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PostModel } from '../view-posts.component';
import { MatSnackBar } from '@angular/material';
import { PostsStoreService } from 'src/app/_services/in-word.store.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-edit-dialog',
  templateUrl: './add-edit-dialog.component.html',
  styleUrls: ['./add-edit-dialog.component.css']
})

export class AddEditDialogComponent implements OnInit {
  editForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<AddEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private fb: FormBuilder, public snackBar: MatSnackBar, private PostsStoreService: PostsStoreService,
    public datepipe: DatePipe) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onUpdateClick() {
    let updatedData = this.editForm.getRawValue();
    updatedData.lastDateOfETenderSubmission = this.datepipe.transform(updatedData.lastDateOfETenderSubmission, 'EEE, dd MMM yyyy hh:mm:ss a')
    updatedData.auctionStartDateTime = this.datepipe.transform(updatedData.auctionStartDateTime, 'EEE, dd MMM yyyy hh:mm:ss a');
    updatedData.auctionEndDateTime = this.datepipe.transform(updatedData.auctionEndDateTime, 'EEE, dd MMM yyyy hh:mm:ss a');
    this.PostsStoreService.updatePost(updatedData).subscribe(response => {
      if (response) {
        this.openSnackBar('Record Updated Successfully', 'Ok');
        this.dialogRef.close();
        location.reload();
      } else {
        this.openSnackBar('Error While Updating Record', 'Ok');
        this.dialogRef.close();
      }

    });
  }
  onSaveClick() {
    let updatedData = this.editForm.getRawValue();
    updatedData.lastDateOfETenderSubmission = this.datepipe.transform(updatedData.lastDateOfETenderSubmission, 'EEE, dd MMM yyyy hh:mm:ss a')
    updatedData.auctionStartDateTime = this.datepipe.transform(updatedData.auctionStartDateTime, 'EEE, dd MMM yyyy hh:mm:ss a');
    updatedData.auctionEndDateTime = this.datepipe.transform(updatedData.auctionEndDateTime, 'EEE, dd MMM yyyy hh:mm:ss a');
    this.PostsStoreService.addPost(updatedData).subscribe(response => {
      if (response) {
        this.openSnackBar('Record Addedd Successfully', 'Ok');
        this.dialogRef.close();
        location.reload();
      } else {
        this.openSnackBar('Error While Adding Record', 'Ok');
        this.dialogRef.close();
      }

    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  ngOnInit() {
    if (this.data.isEdit) {
      this.editForm = this.fb.group({
        id: [this.data.post.id],
        appelentName: [this.data.post.appelentName],
        respondentName: [this.data.post.respondentName],
        judges: [this.data.post.judges],
        decidedDate: [this.datepipe.transform(this.data.post.decidedDate, 'yyyy-MM-ddThh:mm')],
        importantPoints: [this.data.post.importantPoints],
        importantPointsHindi: [this.data.post.importantPointsHindi],
        importantPointsMarathi: [this.data.post.importantPointsMarathi],
        importantPointsGujrati: [this.data.post.importantPointsGujrati],
        headNote: [this.data.post.headNote],
        headNoteHindi: [this.data.post.headNoteHindi],
        headNoteMarathi: [this.data.post.headNoteMarathi],
        headNoteGujrati: [this.data.post.headNoteGujrati],
        result: [this.data.post.result],
        caseReffered: [this.data.post.caseReffered],
        actsReffered: [this.data.post.actsReffered],
        // lastDateOfETenderSubmission: [this.datepipe.transform(this.data.post.lastDateOfETenderSubmission, 'yyyy-MM-dd')],
        // auctionStartDateTime: [this.datepipe.transform(this.data.post.auctionStartDateTime, 'yyyy-MM-ddThh:mm')],
        // auctionEndDateTime: [this.datepipe.transform(this.data.post.auctionEndDateTime, 'yyyy-MM-ddThh:mm')],
        links: [this.data.post.links],
        // auctionFile: [],
        // simliarLink: [this.data.post.simliarLink],
        pid: [this.data.post.pid],
      });
    } else {
      this.editForm = this.fb.group({
        id: [],
        appelentName: [],
        respondentName: [],
        judges: [],
        decidedDate: [],
        importantPoints: [],
        importantPointsHindi: [],
        importantPointsMarathi: [],
        importantPointsGujrati: [],
        headNote: [],
        headNoteHindi: [],
        headNoteMarathi: [],
        headNoteGujrati: [],
        result: [],
        caseReffered: [],
        actsReffered: [],
        links: [],
        pid: [this.data.post.pid],
      });
    }
  }

}
