import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatTableDataSource } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { Utility } from '../_helpers/utility';
import { PostsStoreService } from '../_services/in-word.store.service';
import { AddAdminComponent } from '../_shared/add-admin/add-admin.component';
import { ConfirmComponent, ConfirmData } from '../_shared/confirm/confirm.component';

export interface AdminModel {
  id: String,
  no: String,
  fullName: String,
  email: String,
  mobile: Number,
  gender: String,
  password: String,
  discountValue: Number,
  agentId: String,
  role: String,
  enabled: boolean
}

export interface AdminModelList {
  count: number;
  data: AdminModel[]
}

@Component({
  selector: 'app-view-admins',
  templateUrl: './view-admins.component.html',
  styleUrls: ['./view-admins.component.css']
})
export class ViewAdminsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  adminsData: AdminModel[] = [];
  isLoading = false;
  buttons: MatProgressButtonOptions[] = [];
  displayedColumns: string[] = ['no', 'fullName', 'email', 'mobile', 'gender', 'agentId','discountValue', 'role', 'edit'];
  dataSource = new MatTableDataSource<AdminModel>(this.adminsData);
  public roles = Utility.getCookie('role');

  constructor(private PostsStoreService: PostsStoreService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {
    this.adminsData = [];
    this.buttons = [];
    this.isLoading = true;
    this.PostsStoreService.getAllAdmins().subscribe(result => {
      
      this.adminsData = result;
      this.isLoading = false;
      this.dataSource = new MatTableDataSource<AdminModel>(this.adminsData);
      this.dataSource.paginator = this.paginator;
    });

  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

  addAdmin(element): void {
    const dialogRef = this.dialog.open(AddAdminComponent, {
      width: '550px',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      this.PostsStoreService.getAllAdmins().subscribe(result => {
        
        this.adminsData = result;
        this.dataSource = new MatTableDataSource<AdminModel>(this.adminsData);
        this.dataSource.paginator = this.paginator;
      });
    });
  }

  updateAdmin(value, admin) {
    
    let message = '';
    value.checked ? message = 'Are you sure you want to activate Admin ' + admin.name + '?' : message = 'Are you sure you want to deactive Admin ' + admin.name + '?';
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '550px',
      data: {
        heading: 'Confirm',
        title: message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == ConfirmData.Yes) {
        admin.enabled = value.checked;
        this.PostsStoreService.updateAdminEnabled(admin).subscribe(response => {
          this.openSnackBar('Record Updated Successfully', 'Ok');
        });
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
