import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { Utility } from "src/app/_helpers/utility";
import { InWordEntry } from "src/app/_models/in-word.dto";
import { PostsStoreService } from "src/app/_services/in-word.store.service";
import { PlanModel } from "src/app/view-plans/view-plans.component";

@Component({
  selector: "app-add",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.css"],
})
export class AddComponent implements OnInit {
  filteredRackOptions: Observable<string[]>;
  rackOptions: string[] = [];
  addPlanForm: FormGroup;
  submitted = false;

  constructor(
    public dialogRef: MatDialogRef<AddComponent>,
    // private inwordStoreService: InWordStoreService,
    public snackBar: MatSnackBar,
    private PostsStoreService: PostsStoreService,
    @Inject(MAT_DIALOG_DATA) public data: PlanModel,
    private fb: FormBuilder,
    public datepipe: DatePipe
  ) {}
  private language = [
    { value: 0, viewValue: "English" },
    { value: 1, viewValue: "English + Hindi" },
    { value: 2, viewValue: "English + Marathi" },
    { value: 3, viewValue: "English + Gujrati" },
  ];

  private type = [
    { value: 0, viewValue: "Civil" },
    { value: 1, viewValue: "Criminal" },
    { value: 2, viewValue: "Civil + Criminal" },
  ];

  private courtTypeList = [
    { value: 0, viewValue: "Supreme Court" },
    { value: 1, viewValue: "Bombay High Court" },
    { value: 2, viewValue: "Both" }
  ];

  ngOnInit() {
    this.addPlanForm = this.fb.group({
      discount: new FormControl("", Validators.required),
      language: new FormControl("Required", Validators.required),
      type: new FormControl("", Validators.required),
      months: new FormControl("", Validators.required),
    });
    if (Utility.isNotNullOrUndefinedOrEmpty(this.data)) {
      this.addPlanForm = this.fb.group({
        id: [this.data.id],
        language: [this.data.language],
        courtType: [this.data.courtType],
        type: [this.data.type],
        months: [this.data.months],
        discount: [this.data.discount],
        total: [this.data.total],
        cost: [this.data.cost],
        order: [this.data.order],
        planExpiry: [ this.datepipe.transform(
          this.data.planExpiry,
          "yyyy-MM-dd"
        ),],
      });
    } else {
      this.addPlanForm = this.fb.group({
        id: [],
        language: [],
        courtType: [],
        type: [],
        months: [],
        discount: [],
        total: [],
        cost: [],
        order: [],
        planExpiry: [],
      });
    }
  }

  saveRecord() {
    if (this.addPlanForm.valid) {
      if (Utility.isNotNullOrUndefinedOrEmpty(this.data)) {
        const plansData = this.addPlanForm.getRawValue();
        this.PostsStoreService.updatePlanModel(plansData).subscribe(
          (response) => {
            this.openSnackBar("Record Updated Successfully", "Ok");
            this.dialogRef.close();
          }
        );
      } else {
        const plansData = this.addPlanForm.getRawValue();
        this.PostsStoreService.addPlan(plansData).subscribe((response) => {
          this.openSnackBar("Record Added Successfully", "Ok");
          this.dialogRef.close();
        });
      }
    } else{
      this.submitted = true;
      Utility.markFormGroupTouched(this.addPlanForm);
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  get months() {
    return this.addPlanForm.get('months');
  }

  get discount() {
    return this.addPlanForm.get('discount');
  }
  get total() {
    return this.addPlanForm.get('total');
  }
  get cost() {
    return this.addPlanForm.get('cost');
  }
  get courtType() {
    return this.addPlanForm.get('courtType');
  }
  get order() {
    return this.addPlanForm.get('order');
  }
  get planExpiry() {
    return this.addPlanForm.get('planExpiry');
  }
}
